import { tag } from "@blotoutio/edgetag-sdk-js";
import { isBrowser } from "../helpers";

function blotoutSendPageView() {
  if (!isBrowser) return;
  tag("PageView");
}

function blotoutSearch({ query, searchResults }) {
  if (!isBrowser) return;

  const searchProducts = searchResults.map((item) => ({
    id: item.shopify.id.replace("gid://shopify/Product/", ""),
    quantity: 1,
    item_price: parseFloat(item.shopify.variants[0].price),
    sku: item.shopify.variants[0].sku,
    title: item.contentful.title,
    description: item.contentful.description,
    category: item.shopify.category,
    brand: "Ana Luisa",
    image: item.shopify.images[0].url,
    url: `https://www.analuisa.com/products/${item.shopify.handle}/`,
  }));
  tag("Search", {
    search: query,
    contents: searchProducts,
    currency: "USD",
  });
}

function blotoutQuickViewProduct({ productPrice, currencyCode, product }) {
  if (!isBrowser) return;
  tag("ViewContent", {
    currency: currencyCode,
    value: parseFloat(productPrice),
    contents: [
      {
        id: product.shopifyId.replace("gid://shopify/Product/", ""),
        quantity: 1,
        item_price: parseFloat(productPrice),
        title: product.title,
        category: product.productType,
        image: product.images[0].url,
        url: `https://www.analuisa.com/products/${product.handle}`,
      },
    ],
  });
}

function blotoutViewProduct({ product }) {
  if (!isBrowser) return;

  tag("ViewContent", {
    currency: product?.currencyCode || "USD",
    value: parseFloat(product.final_price),
    contents: [
      {
        id: product.shopifyId.replace("gid://shopify/Product/", ""),
        quantity: 1,
        item_price: parseFloat(product.final_price),
        title: product.title,
        category: product.productType,
        image: product.images[0].url,
        url: `https://www.analuisa.com/products/${product.handle}`,
      },
    ],
  });
}

function blotoutSendAddToCart({
  price,
  title,
  variantId,
  category,
  currency,
  quantity,
  productHandle,
  images,
  checkoutUrl,
}) {
  if (!isBrowser) return;

  tag("AddToCart", {
    currency,
    value: price,
    contents: [
      {
        id: variantId,
        quantity,
        item_price: price,
        title,
        category,
        image: images && images.length > 0 ? images[0].url : "",
        url: `https://www.analuisa.com/products/${productHandle}`,
      },
    ],
    checkoutUrl,
  });
}

export {
  blotoutSendPageView,
  blotoutSendAddToCart,
  blotoutViewProduct,
  blotoutQuickViewProduct,
  blotoutSearch,
};
