import { PropTypes } from "prop-types";
import { Link } from "gatsby";

import useResponsiveWithHydrationFix from "../../../../../../../hooks/useResponsiveWithHydrationFix";

import ArrowRoundSm from "../../../../../../icons/svgs/arrow-circle-sm.svg";

import * as Styles from "./searchCard/styles.module.scss";

function SearchCard({ card }) {
  const backgroundImage = useResponsiveWithHydrationFix(
    card.image.mobileImage.file.url,
    card.image.desktopImage.file.url
  );
  const { link } = card.image;

  if (link && link?.title !== "empty") {
    return (
      <Link
        className={Styles.search_card}
        style={{
          backgroundImage: card.image ? `url(${backgroundImage})` : "none",
        }}
        to={link?.link?.link}
      >
        <p className={`${Styles.title} text text--bold`}>{card.title}</p>
        {card.showArrow && <ArrowRoundSm width="21" stroke="#2D2927" />}
      </Link>
    );
  }
  return (
    <div
      className={Styles.search_card}
      style={{
        backgroundImage: card.image ? `url(${backgroundImage})` : "none",
      }}
    >
      <p className={`${Styles.title} text text--bold`}>{card.title}</p>
      {card.showArrow && <ArrowRoundSm width="21" stroke="#2D2927" />}
    </div>
  );
}

SearchCard.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.shape({
      link: PropTypes.shape({
        title: PropTypes.string,
        link: PropTypes.shape({
          link: PropTypes.string,
        }),
      }),
      mobileImage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      desktopImage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    showArrow: PropTypes.bool,
  }),
};
export default SearchCard;
