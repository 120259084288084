import { PropTypes } from "prop-types";

import { useLocalizedSentenceDict } from "../../../../../hooks/useSentenceDict";

import Categories from "../Categories";
import BrowseProducts from "../../../../blocks/BrowseProducts";
import SearchCards from "./topSearches/SearchCards";
import { ComponentType } from "../../../../../constants/ComponentType";

import * as Styles from "./topSearches/styles.module.scss";

function TopSearches({
  refine,
  topCategories,
  cards,
  productsCarousel,
  productsCarouselFailedSearches,
  nbHits,
  currentRefinement,
}) {
  const dict = useLocalizedSentenceDict();

  if (currentRefinement && nbHits > 0) {
    return null;
  }

  return (
    <div className={Styles.topSearches}>
      <div className={Styles.topSearches__wrapper}>
        <div className={Styles.topSearches__wrapper_content}>
          <h3 className={`h3 h3--bold h3--uc ${Styles.topSearches__title}`}>
            {nbHits === 0 ? dict.get("No matches found") : dict.get("Top Searches")}
          </h3>
          {nbHits === 0 && (
            <p className="subtext">
              {dict.get(
                "Try another search or maybe we can interest you with these popular search terms:"
              )}
            </p>
          )}
          <Categories refine={refine} categories={topCategories} />
        </div>
        {nbHits > 0 && cards && cards.length > 0 && (
          <div className={Styles.topSearches__cards}>
            <SearchCards cards={cards} />
          </div>
        )}
      </div>
      {nbHits === 0 && productsCarouselFailedSearches && (
        <BrowseProducts
          content={productsCarouselFailedSearches}
          componentType={ComponentType.SEARCH}
        />
      )}
      {nbHits > 0 && productsCarousel && (
        <BrowseProducts content={productsCarousel} componentType={ComponentType.SEARCH} />
      )}
    </div>
  );
}

TopSearches.propTypes = {
  refine: PropTypes.func,
  topCategories: PropTypes.arrayOf(PropTypes.string),
  cards: PropTypes.arrayOf(PropTypes.shape({})),
  productsCarousel: PropTypes.shape({}),
  productsCarouselFailedSearches: PropTypes.shape({}),
  nbHits: PropTypes.number,
  currentRefinement: PropTypes.string,
};

export default TopSearches;
