import axios from "axios";
import { useEffect, useState } from "react";
import { user, tag } from "@blotoutio/edgetag-sdk-js";
import { useCurrentCountryCode } from "../hooks/usePrices";
import { isBrowser, sanitizePhonePerCountry, validateEmail } from "../context/helpers";
import { useAuth } from "../context/AuthProvider";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

export default function JustUnoTracking() {
  const countryCode = useCurrentCountryCode();
  const [isJustUnoTrackedImpression, setJustUnoTrackedImpression] = useState(false);
  const { accountDetails } = useAuth();
  const { sendReport } = useALError();

  const hasJuapp = isBrowser && typeof window.juapp !== "undefined";
  const customerEmail = accountDetails?.email || (isBrowser && window.exponea?.email_id);

  useEffect(() => {
    if (hasJuapp) {
      window.juapp(
        "trackFunc",
        "engagement",
        (email, eventname, promotitle, cmid, options, form) => {
          // Extract Phone Number from form
          const _phoneNumber = form.find((e) => e.name.toLowerCase() === "phone_number")
            ? form.find((e) => e.name.toLowerCase() === "phone_number")?.value
            : null;

          if (_phoneNumber) {
            // Add phone number to Exponea
            const _phone = sanitizePhonePerCountry(_phoneNumber);
            window.exponea.identify(
              {
                phone_id: _phone,
              },
              {
                phone: _phone,
              }
            );

            try {
              user("phone", _phone);
              tag("Lead", {
                name: "JustUno Popup",
              });
            } catch (error) {
              sendReport(error, { name: "JustUnoTracking", priority: "P1" });
            }

            // AL Reward - Customer added phone number
            if (cmid == 893324) {
              try {
                axios.post(`/.netlify/functions/loyalty-lion-activities`, {
                  email: customerEmail,
                  ruleName: "subscribe_sms",
                  ruleState: "approved",
                  countryCode,
                });
              } catch (error) {
                sendReport(error, { name: "Layout", priority: "P1" });
              }
            }
          }

          if (validateEmail(email)) {
            window.exponea.identify(
              {
                email_id: email,
              },
              {
                email,
              }
            );

            window.exponea.track("subscribe_justuno_popup", {
              event_name: eventname,
              title: promotitle,
              id: cmid,
              phone: _phoneNumber,
            });

            // Trigger GTM subscribe_popup
            if (window.dataLayer) {
              window.dataLayer.push({
                event: "subscribe_popup",
                title: promotitle,
                id: cmid,
              });
            }

            try {
              user("email", email);
            } catch (error) {
              sendReport(error, { name: "JustUnoTracking", priority: "P1" });
            }
          }
        }
      );
      setJustUnoTrackedImpression(true);

      if (!isJustUnoTrackedImpression) {
        window.juapp("trackFunc", "impression", (email, eventname, promotitle, cmid) => {
          window.exponea.track("view_justuno_popup", {
            event_name: eventname,
            title: promotitle,
            id: cmid,
          });
        });
        setJustUnoTrackedImpression(true);
      }
    }
  }, [hasJuapp]);

  return null;
}
