import { Link } from "gatsby";
import { PropTypes } from "prop-types";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { resizeShopifyImage } from "../../../context/helpers";
import { ALButton } from "../../ALComponents";

import { useFillProductPrices, usePriceInfo } from "../../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";

import PriceContainer from "../../PriceContainer";
import { ComponentType } from "../../../constants/ComponentType";

import "./upSellItem/styles.scss";

function UpSellItem({
  product: originalProduct,
  onAddToCart,
  atcButtonDisabled = false,
  atcButtonText = "ADD",
}) {
  const product = useFillProductPrices({ product: originalProduct, shouldUseDiscount: true });
  const dict = useLocalizedSentenceDict();

  const isFinalSale = (product?.tags || []).includes("Final Sale");

  const [{ data: prices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [product.handle],
    tags: { [product.handle]: product?.tags },
  });

  return (
    <div className="upsell_item" key={product.handle}>
      <Link
        className="upsell_item__link"
        to={`/products/${product.handle}?origin_content=recommended-upsell_sidecart`}
      >
        <img
          src={resizeShopifyImage(product.images[0].url, "x200")}
          alt={product.images[0].altText}
        />
      </Link>
      <div className="upsell_item__content">
        <div className="upsell_item__content_title_price">
          <p className="subtext subtext--uc subtext--bold upsell_item__content_title">
            {product.title.split(" - ")[1] || product.title}
          </p>
          <PriceContainer
            productId={product.id}
            tags={product.tags}
            selectedVariant={product.variants[0]}
            componentType={ComponentType.SIDE_CART_UPSELL}
            prices={prices}
            pricesLoading={pricesLoading}
          />
        </div>

        {isFinalSale && (
          <p className="upsell_item__content_subtitle subtext">{dict.get("Final Sale")}</p>
        )}

        <ALButton
          variant="secondary"
          size="xsmall"
          fullWidth
          className="upsell_item__content_cta"
          onClick={() => {
            if (typeof onAddToCart === "function") {
              onAddToCart(product);
            }
          }}
          disabled={atcButtonDisabled}
        >
          {dict.get(atcButtonText)}
        </ALButton>
      </div>
    </div>
  );
}

UpSellItem.propTypes = {
  product: PropTypes.shape({
    handle: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.array,
    images: PropTypes.array,
  }),
  onAddToCart: PropTypes.func,
  atcButtonDisabled: PropTypes.bool,
  atcButtonText: PropTypes.string,
};

export default withALErrorBoundary({
  name: "UpSellItem",
  priority: "P2",
})(UpSellItem);
