export const KeyToNames = {
  components: "materials",
  caratWeight: "carat",
};

export const DetailsOrderedKeys = [
  "components",
  "length",
  "width",
  "height",
  "depth",
  "weight",
  "pendantDimensions",
  "chainWidth",
  "charmDimensions",
  "caratWeight",
  "clarity",
  "stoneDiameter",
  "clasp",
  "hoopDiameter",
  "innerDiameter",
  "post",
  "craftedIn",
];
