const shopifyConfigs = [
  {
    name: process.env.GATSBY_SHOPIFY1_STORE_NAME,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY1_ACCESS_TOKEN,
    headlessStorefrontAccessToken: process.env.GATSBY_SHOPIFY1_HEADLESS_ACCESS_TOKEN,
    loyaltyLionToken: process.env.GATSBY_SHOPIFY1_LOYALTY_LION_TOKEN,
    kustomerId: "6137de4f0087fc71664d3bf1", // scheduleId
    kustomerBrandId: "6058e868ac2513422fedeef6",
    customerSupportEmail: "love@analuisa.com",
    store: "US_ROW",
    knowledgeBase: "https://faq.analuisa.com",
    customerSupport: {
      email: "love@analuisa.com",
      startHours: "1991-07-01T13:00:00.386Z", // 9AM (1pm for UTC) (9 + 4 hours = 13)
      endHours: "1991-07-01T22:00:00.386Z", // 6PM (22pm for UTC) (18 + 4 hours = 22)
      sentence: "{0}-{1} EST",
    },
  },
];

const defaultConfig = {
  pathPrefix: "",
  shopify: shopifyConfigs[0],
  lang: "en-US",
  countries: ["US"],
};

const getAllStoreConfigs = () => [defaultConfig];
exports.getAllStoreConfigs = getAllStoreConfigs;

const getShopifyStoreByCountry = () => defaultConfig.shopify;
exports.getShopifyStoreByCountry = getShopifyStoreByCountry;

function algoliaIndexBaseKey(lang, storeName) {
  return `${lang}-${storeName}`;
}

exports.getAlgoliaIndexBaseKey = (countryCode, lang) =>
  algoliaIndexBaseKey(lang, getShopifyStoreByCountry(countryCode).name);

/**
 * returns [
 *   {
 *     algoliaBaseKey: 'en-analuisaparis',
 *     lang: 'en',
 *     firstCountryCode: 'US' // this is used to fetch product from Shopify. We are not using the price here so it's ok to just get the first country.
 *   },
 *   ...
 * ]
 */
exports.getAllAlgoliaIndexBaseKeys = () =>
  getAllStoreConfigs().map((c) => ({
    algoliaBaseKey: algoliaIndexBaseKey(c.lang, c.shopify.name),
    lang: c.lang,
    firstCountryCode: c.countries[0],
  }));
