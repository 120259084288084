import { createContext, useEffect, useState, useCallback } from "react";
import { isBrowser } from "./helpers";
import { useTracking } from "./Tracking";
import { useAuth } from "./AuthProvider";

const WISHLIST_KEY = "wishlist";

const defaultWishlistContext = {
  addItemToWishlist: () => {},
  removeItemFromWishlist: () => {},
  wishlistItems: null,
  isLoaded: false,
};
export const WishlistContext = createContext(defaultWishlistContext);

function WishlistProvider({ children }) {
  const { trackAddToWishlist, trackRemoveFromWishlist } = useTracking();
  const { accountDetails } = useAuth();
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isLoaded, setLoadedWishlist] = useState(false);

  const getLocalWishlist = useCallback(
    () => localStorage.getItem(WISHLIST_KEY)?.split(",") || [],
    []
  );

  const saveWishlistToStorage = useCallback((items) => {
    const filteredItems = items.filter((h) => h); // filter out empty strings

    localStorage.setItem(WISHLIST_KEY, filteredItems.join(","));
    window.exponea.update({ wishlist: filteredItems.toString() });

    return filteredItems;
  }, []);

  // wishlist items will be added and controlled via local storage. It will check if any items in exponea are in the local wishlist and if not, it will add that product. The reason for this is because whatever the local storage is will become the state of exponea's wish list.
  const addItemToWishlist = ({ type, productHandle, productName, price, productId, currency }) => {
    if (isBrowser) {
      const wishlist = new Set(getLocalWishlist());
      wishlist.add(productHandle);

      const items = Array.from(wishlist);

      saveWishlistToStorage(items);
      setWishlistItems(items);

      trackAddToWishlist({
        type,
        productHandle,
        productName,
        price,
        productId: productId.replace("gid://shopify/Product/", ""),
        currency,
      });
    }
  };

  // Same concept as add, no need to check for comparing.
  const removeItemFromWishlist = ({ productHandle, price, productId }) => {
    if (isBrowser) {
      const wishlist = new Set(getLocalWishlist());
      wishlist.delete(productHandle);

      const items = Array.from(wishlist);

      saveWishlistToStorage(items);

      setWishlistItems(items);

      trackRemoveFromWishlist({ productHandle, price, productId });
    }
  };

  const getAndSyncWishlistProducts = useCallback(() => {
    if (isBrowser) {
      const localWishlist = getLocalWishlist();

      const exponeaWislistItems = window.exponea?.wishlistItems || [];

      const wishlistItems = new Set([...exponeaWislistItems, ...localWishlist]);

      const items = Array.from(wishlistItems);

      return saveWishlistToStorage(items);
    }
    return [];
  }, [getLocalWishlist, saveWishlistToStorage]);

  useEffect(() => {
    const retrieveWishlist = (retries = 0) => {
      if (isBrowser && window.exponea.identified === "true") {
        const items = getAndSyncWishlistProducts();
        setWishlistItems(items);
        setLoadedWishlist(true);
      } else if (retries > 2) {
        const localWishlist = getLocalWishlist();
        setWishlistItems(localWishlist);
        setLoadedWishlist(true);
      } else {
        setTimeout(() => retrieveWishlist(retries + 1), 1000);
      }
    };

    if (accountDetails) {
      window.exponea.identify(
        {
          email_id: accountDetails.email,
        },
        {
          email: accountDetails.email,
        }
      );
      retrieveWishlist();
    } else {
      // if the customer logs off, it removes AND resets the wishlist
      localStorage.removeItem(WISHLIST_KEY);
      setWishlistItems([]);
      setLoadedWishlist(true);
    }
  }, [accountDetails, getAndSyncWishlistProducts]);

  return (
    <WishlistContext.Provider
      value={{
        ...defaultWishlistContext,
        addItemToWishlist,
        removeItemFromWishlist,
        wishlistItems,
        isLoaded,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
}

export default WishlistProvider;
