import { useState, useEffect, createContext, useCallback, useMemo } from "react";
import { PropTypes } from "prop-types";

import { isBrowser, getCookie, createCookie, getAllUrlParams } from "./helpers";
import { alReportError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentCountryCode } from "../hooks/usePrices";

import DISCOUNT_TYPES from "../constants/DiscountTypes";
import WHITELIST from "../constants/WhitelistedDiscount";

export const defaultDiscountContext = {
  discountInfo: null,
  isDoublePoints: false,
  localizedDiscount: [],
  setFinalDiscount: () => {},
};

export const DiscountContext = createContext(defaultDiscountContext);

function useLocalizedDiscount(countryCode, discount) {
  return useMemo(() => {
    if (!countryCode) {
      return { localizedDiscount: [] };
    }
    const defaultDiscount = discount.edges.find((d) =>
      d.node.countries.find((c) => c.code === "RoW")
    );
    const newDiscount =
      discount.edges.find((d) => d.node.countries.find((c) => c.code === countryCode)) ??
      defaultDiscount;
    if (!newDiscount?.node) {
      return { localizedDiscount: [] };
    }

    return {
      localizedDiscount: [newDiscount?.node], // make a single-element array so that the rest of the code can pick it up properly.
    };
  }, [countryCode, discount]);
}

function DiscountProvider({ discount, children }) {
  const [store, updateStore] = useState(defaultDiscountContext);
  const countryCode = useCurrentCountryCode();
  const { localizedDiscount } = useLocalizedDiscount(countryCode, discount);

  const discountLocal = useMemo(() => {
    if (!isBrowser) {
      return "empty";
    }
    try {
      // Extract discount from cookie

      // TODO: Remove this part for 4 days (previous cookie validation time)
      // const cookieDiscountAmount = getCookie("dAmount");
      // const cookieDiscountCode = getCookie("dCode");
      // const cookieDiscountType = getCookie("dType");
      // const cookieDiscountSource = getCookie("dSource");
      const cookieDiscountAmount = null;
      const cookieDiscountCode = null;
      const cookieDiscountType = null;
      const cookieDiscountSource = null;
      // Init discount local from cookies
      let initDiscountLocal = "empty";

      // Set discount from urls params
      if (
        cookieDiscountAmount &&
        cookieDiscountCode &&
        cookieDiscountType &&
        cookieDiscountSource
      ) {
        if (cookieDiscountCode !== "VIEW25") {
          initDiscountLocal = {
            dSource: cookieDiscountSource,
            dAmount: cookieDiscountAmount,
            dCode: cookieDiscountCode,
            dType: cookieDiscountType,
            isFromParams: true,
          };
        }
      }

      const uSource = getAllUrlParams()?.utm_source;
      if (uSource && WHITELIST.sourceAccepted.includes(uSource)) {
        // Extract discount info from url
        const uDiscountCode = getAllUrlParams()?.discount_code;
        let uDiscountAmount = getAllUrlParams()?.discount_a;
        const uDiscountType =
          getAllUrlParams()?.discount_t === "percentage"
            ? DISCOUNT_TYPES.SITEWIDE_PERCENT
            : getAllUrlParams()?.discount_t;

        if (uDiscountCode === "GET20") {
          uDiscountAmount = 25;
        }
        if (uDiscountCode && uDiscountType && uDiscountAmount) {
          // Store new discount to cookies
          const days = 1 / 24; // 1 / 24 days (1h) before Cookie expire
          createCookie("dSource", uSource, days);
          createCookie("dAmount", uDiscountAmount, days);
          createCookie("dCode", uDiscountCode, days);
          createCookie("dType", uDiscountType, days);

          // Set discount from urls params
          initDiscountLocal = {
            dSource: uSource,
            dAmount: uDiscountAmount,
            dCode: uDiscountCode,
            dType: uDiscountType,
            isFromParams: true,
            exclusionTags: ["Final Sale", "EXCLUSION"],
          };
        }
      }

      return initDiscountLocal;
    } catch (e) {
      alReportError(e);
      return "empty";
    }
  }, []);

  const finalDiscount = useMemo(() => {
    let finalDiscount = {
      isMaster: (localizedDiscount && localizedDiscount[0]?.isMaster) ?? false,
    };

    if (localizedDiscount !== null) {
      const dSource = getCookie("dSource");
      const dCode = getCookie("dCode");

      if (WHITELIST.codes.includes(dCode) || WHITELIST.sources.includes(dSource)) {
        finalDiscount.type = getCookie("dType");
        finalDiscount.value = parseInt(getCookie("dAmount"), 10);
        finalDiscount.hasCrossedPrice = getCookie("dType") === DISCOUNT_TYPES.SITEWIDE_PERCENT;
        finalDiscount.code = getCookie("dCode");
        finalDiscount.isFromParams = true;
        finalDiscount.exclusionTags = ["Final Sale", "EXCLUSION"];
      }
      if (dCode === "GET20") {
        finalDiscount.value = 25;
      }

      if (
        localizedDiscount &&
        localizedDiscount[0] &&
        (discountLocal == null ||
          discountLocal === "empty" ||
          localizedDiscount[0]?.isMaster === true)
      ) {
        finalDiscount.type = localizedDiscount[0].type;
        finalDiscount.minimumPurchaseAmount = localizedDiscount[0].minimumPurchaseAmount;
        finalDiscount.value = localizedDiscount[0].percentageValue;
        finalDiscount.hasCrossedPrice = localizedDiscount[0].percentage_crossedPrice;
        finalDiscount.code = localizedDiscount[0].discountCode;
        finalDiscount.saleTitle =
          localizedDiscount[0].sideCartTitle === "null"
            ? false
            : localizedDiscount[0].sideCartTitle;

        if (finalDiscount.type === DISCOUNT_TYPES.QUANTITY_PERCENT) {
          const compareNumbers = (a, b) => parseFloat(a) - parseFloat(b);
          localizedDiscount[0].thresholdAmountQuantity.sort(compareNumbers);
          localizedDiscount[0].thresholdPercentageValue.sort(compareNumbers);
          finalDiscount.discounts = localizedDiscount[0].thresholdAmountQuantity.map((q, idx) => [
            parseInt(q, 10),
            parseFloat(localizedDiscount[0].thresholdPercentageValue[idx]),
          ]);
        }
        if (
          localizedDiscount[0]?.giftMinAmount &&
          localizedDiscount[0]?.giftProductHandle &&
          localizedDiscount[0].giftProductHandle !== "null"
        ) {
          finalDiscount.freegift = {
            minAmount: localizedDiscount[0].giftMinAmount,
            product: { handle: localizedDiscount[0].giftProductHandle },
          };
        } else {
          finalDiscount.freegift = null;
        }
        finalDiscount.exclusionTags = localizedDiscount[0]?.exclusionTags;
        finalDiscount.luxeExclusionTags = localizedDiscount[0]?.luxeExclusionTags;
      } else if (
        discountLocal != null &&
        discountLocal !== "empty" &&
        localizedDiscount[0]?.isMaster === false
      ) {
        finalDiscount.type = getCookie("dType");
        finalDiscount.value = parseInt(getCookie("dAmount"), 10);
        finalDiscount.hasCrossedPrice = getCookie("dType") === DISCOUNT_TYPES.SITEWIDE_PERCENT;
        finalDiscount.code = getCookie("dCode");
        finalDiscount.isFromParams = true;
        finalDiscount.exclusionTags = ["Final Sale", "EXCLUSION"];
      }
    } else if (discountLocal != null && discountLocal !== "empty") {
      finalDiscount.type = getCookie("dType");
      finalDiscount.value = parseInt(getCookie("dAmount"), 10);
      finalDiscount.hasCrossedPrice = getCookie("dType") === DISCOUNT_TYPES.SITEWIDE_PERCENT;
      finalDiscount.code = getCookie("dCode");
      finalDiscount.isFromParams = discountLocal?.isFromParams;
      finalDiscount.exclusionTags = ["Final Sale", "EXCLUSION"];
    } else {
      finalDiscount = "empty";
    }

    // Keep for a while, debug & report against discount usage
    if (isBrowser && window.exponea && finalDiscount?.code) {
      const debugDiscountData = {
        code: finalDiscount?.code,
        type: finalDiscount?.type,
        value: finalDiscount?.value,
        cookie_code: "",
        cookie_value: "",
        cookie_source: "",
        cookie_type: "",
      };
      if (discountLocal !== "empty") {
        debugDiscountData.cookie_code = discountLocal?.dCode;
        debugDiscountData.cookie_value = discountLocal?.dAmount;
        debugDiscountData.cookie_source = discountLocal?.dSource;
        debugDiscountData.cookie_type = discountLocal?.dType;
      }
      window.exponea.track("set_discount", debugDiscountData);
    }

    return finalDiscount;
  }, [discountLocal, localizedDiscount]);

  useEffect(() => {
    if (localizedDiscount && localizedDiscount[0]) {
      store.isDoublePoints = localizedDiscount[0].isDoublePoints;
    }
  }, [store, localizedDiscount]);

  const setFinalDiscount = useCallback(
    (update) => {
      updateStore((latestStore) => ({
        ...latestStore,
        finalDiscount: { ...finalDiscount, ...update },
      }));
    },
    [finalDiscount]
  );

  const providerData = useMemo(
    () => ({
      ...store,
      setFinalDiscount,
      localizedDiscount,
      discountLocal,
      discountInfo: store.finalDiscount || finalDiscount,
    }),
    [store, setFinalDiscount, localizedDiscount, discountLocal, finalDiscount]
  );

  return <DiscountContext.Provider value={providerData}>{children}</DiscountContext.Provider>;
}

DiscountProvider.propTypes = {
  discount: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node_locale: PropTypes.string,
        title: PropTypes.string,
        sideCartTitle: PropTypes.string,
        type: PropTypes.string,
        percentageValue: PropTypes.number,
        isMaster: PropTypes.bool,
        isDoublePoints: PropTypes.bool,
        discountCode: PropTypes.string,
        minimumPurchaseAmount: PropTypes.number,
        countries: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.string,
          })
        ),
        giftProductHandle: PropTypes.string,
        giftMinAmount: PropTypes.number,
        exclusionTags: PropTypes.arrayOf(PropTypes.string),
        luxeExclusionTags: PropTypes.arrayOf(PropTypes.string),
      })
    ).isRequired,
  }),
  products: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          handle: PropTypes.string,
          storeName: PropTypes.string,
          tags: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
  }),
  children: PropTypes.node,
};

export default DiscountProvider;
