import startCase from "lodash/startCase";
import PropTypes from "prop-types";

import { KeyToNames, DetailsOrderedKeys } from "../../../constants/ProductDetailKeys";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { ComponentType } from "../../../constants/ComponentType";

import "./details/styles.scss";

function Details({ details, title, componentType }) {
  const dict = useLocalizedSentenceDict();

  if (!details) {
    return null;
  }

  return (
    <div className="product_details_items">
      {title && <p className="product_details_items_title">{title}</p>}
      {DetailsOrderedKeys.map((key) => {
        const detailTitle = startCase(KeyToNames[key]) || startCase(key);
        let detailValue = details[key];
        if (Array.isArray(details[key])) {
          detailValue = details[key].reduce(
            (red, k, i) => (red += i < details[key].length - 1 ? `${dict.get(k)}, ` : dict.get(k)),
            ""
          );
        }

        // Skip craftedIn for PDP_ADS
        if (componentType === ComponentType.PDP_ADS && key === "craftedIn") {
          return null;
        }

        // No product or "null" value
        if (!details || details[key] === "null") {
          return null;
        }

        // Skip if array contains "null"
        if (Array.isArray(details[key]) && details[key].includes("null")) {
          return null;
        }

        return (
          <div className="product_details_item" key={key}>
            <p className="product_details_item_title">{dict.get(detailTitle)}</p>
            <p className="product_details_item_value" data-testid={`${key}-value`}>
              {detailValue}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export const detailsPropTypes = {
  details: PropTypes.object,
  title: PropTypes.string,
  componentType: PropTypes.string,
};

Details.propTypes = detailsPropTypes;

export default withALErrorBoundary({
  name: "Details",
  priority: "P3",
})(Details);
