import { memo } from "react";
import PropTypes from "prop-types";
import useIsHydrationComplete from "../../hooks/useIsHydrationComplete";
import ALSkeleton from "./ALSkeleton";

export function createShopifyUrl({ url, width, height, options = {} }) {
  const { cropFocus, paddingColor } = options;
  const shopifyURLArguments = {
    width,
    height,
    crop: cropFocus || (width && height && "center") || undefined,
    pad_color: paddingColor,
  };

  const urlObject = new URL(url);
  Object.entries(shopifyURLArguments).forEach(([key, value]) => {
    if (value !== undefined) {
      urlObject.searchParams.append(key, value);
    }
  });
  return urlObject.toString();
}

export function generateHighResSrcSet({ url, width, height }) {
  const standardResolution = createShopifyUrl({ url, width, height });
  const highResolution = createShopifyUrl({
    url,
    width: width && width * 2,
    height: height && height * 2,
  });
  return `${standardResolution} 1x, ${highResolution} 2x`;
}

function ALShopifyImage({
  url,
  alt,
  width,
  height,
  srcSet,
  size,
  className = "",
  loading = "lazy",
  decoding = "async",
}) {
  const isHydrationDone = useIsHydrationComplete();

  const generatedSrcSet = (width || height) && generateHighResSrcSet({ url, width, height });

  return (
    <div className={`al-shopify-image ${className}`}>
      {isHydrationDone ? (
        <img
          loading={loading}
          alt={alt}
          src={url}
          decoding={decoding}
          srcSet={srcSet || generatedSrcSet}
          size={size}
          width={width}
          height={height}
        />
      ) : (
        <ALSkeleton />
      )}
    </div>
  );
}
ALShopifyImage.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.string,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  decoding: PropTypes.string,
  srcSet: PropTypes.string,
  size: PropTypes.string,
};

export default memo(ALShopifyImage);
