import PropTypes from "prop-types";

import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { ALButton } from "../ALComponents";
import SideCartTotalTieredDiscount from "./SideCartTotalTieredDiscount";
import LockIcon from "../icons/svgs/lock.svg";

function CheckoutButton({
  checkoutUrl,
  isCheckoutDisabled,
  estimatedTotal,
  fullAmount,
  goToCheckout,
  isLuxe,
}) {
  const dict = useLocalizedSentenceDict();

  if (isCheckoutDisabled) {
    return (
      <div>
        <ALButton size="large" fullWidth disabled>
          {dict.get("SECURE CHECKOUT")}
          <LockIcon width="17" height="21" fill="none" stroke="white" className="ml-20" />
        </ALButton>
        <p className="sidecart__message--disabled">
          You&apos;re nearly there! <span className="bold">Add another item</span> to check out.
        </p>
      </div>
    );
  }

  return (
    <div>
      <ALButton
        variant={isLuxe ? "black" : "primary"}
        size="large"
        fullWidth
        className="checkout__button"
        onClick={() => goToCheckout(checkoutUrl)}
      >
        <div className="checkout_container">
          <div className="checkout_title_icon">
            {dict.get("SECURE CHECKOUT")}
            <LockIcon
              width="17"
              height="21"
              fill="none"
              stroke="#FBF7EC"
              className="ml-15 checkout_lock_icon"
            />
          </div>

          <div className="checkout_sidecart_total">
            <SideCartTotalTieredDiscount estimatedTotal={estimatedTotal} fullAmount={fullAmount} />
          </div>
        </div>
      </ALButton>
    </div>
  );
}

CheckoutButton.propTypes = {
  checkoutUrl: PropTypes.string,
  isCheckoutDisabled: PropTypes.bool,
  estimatedTotal: PropTypes.string,
  fullAmount: PropTypes.number,
  goToCheckout: PropTypes.func,
};

export default CheckoutButton;
