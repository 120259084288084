import {
  createCookie,
  deleteCookie,
  loadScriptHead,
  isBrowser,
  hashEmail,
  getAllUrlParams,
} from "./src/context/helpers";
import { trackPageView } from "./src/context/Tracking";

export const onInitialClientRender = () => {
  window.__isHydrated = true;
};

export const onClientEntry = () => {
  window.ElevarDataLayer = window.ElevarDataLayer ?? [];
};

export const onPreRouteUpdate = ({ location }) => {
  const paramsURL = getAllUrlParams(location?.href);
  if (paramsURL?.irclickid) {
    // Store irclickid in cookies for 30 days (+ update cookie)
    createCookie("_irclickid", paramsURL?.irclickid, 30);
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // This is needed after we switch to Gatsby Script to load script.
  // We need to make sure everything is ready before calling these
  // analytics functions.

  runUntil(
    () => {
      if (window.ElevarInvalidateContext) {
        window.ElevarInvalidateContext?.();
      }
    },
    () => window.ElevarInvalidateContext
  );

  runUntil(
    () => {
      trackPageView();
    },
    () => window?.gtag && window?.fbq && window?.ttq && window?.pintrk
  );

  runUntil(
    () => {
      // Reload Impact script for each page change
      loadScriptHead("https://utt.impactcdn.com/A3670505-08a4-4003-a105-fc58ad27da3c1.js").then(
        () => {
          const exponeaEmail = isBrowser && window.exponea?.email_id;
          const email = hashEmail(exponeaEmail);
          const data = email
            ? {
                customerId: email,
                customerEmail: email,
              }
            : {
                customerId: "",
                customerEmail: "",
              };
          window.ire("identify", data);
        }
      );
    },
    () => window?.exponea
  );

  if (
    ((!prevLocation || prevLocation?.pathname?.includes("/collections/")) &&
      !location?.pathname?.includes("/products/")) ||
    location?.search?.includes("?origin_content=")
  ) {
    // Remove cookies path
    deleteCookie("bread_url");
    deleteCookie("bread_title");
  }
};

/**
 * Do not run actionFun until conditionFun returns true
 * @param {*} actionFun
 * @param {*} conditionFun
 */
function runUntil(actionFun, conditionFun) {
  if (conditionFun()) {
    actionFun();
  } else {
    setTimeout(() => {
      runUntil(actionFun, conditionFun);
    }, 100);
  }
}
