import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { ComponentType } from "../../constants/ComponentType";
import "./productReviewRating/styles.scss";

function ProductReviewRating({
  scrollToReviews = null,
  type,
  openReviewsPopup = () => {},
  productId,
}) {
  return (
    <div
      className={`pdp_details_reviews 
      ${type === ComponentType.REVIEWS ? "pdp_details_reviews_list" : ""}
        ${type === ComponentType.PRODUCT_CAROUSEL ? "product_carousel_details_reviews" : ""}`}
      onClick={() => {
        if (scrollToReviews !== null) {
          return scrollToReviews();
        }
        openReviewsPopup(true);
      }}
      data-testid="PDP-block"
    >
      <div
        data-bv-show="rating_summary"
        data-bv-product-id={productId?.replace("gid://shopify/Product/", "")}
      />
    </div>
  );
}

export default withALErrorBoundary({
  name: "ProductReviewRating",
  priority: "P2",
})(ProductReviewRating);
