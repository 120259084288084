import { PropTypes } from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";

import useDeviceDetect from "../../../../../../hooks/useDeviceDetect";

import IconArrowCircleMd from "../../../../../icons/svgs/arrow-circle-md.svg";
import SearchCard from "./searchCards/SearchCard";

import "./searchCards/styles.scss";

function SearchCards({ cards }) {
  const { isMobile } = useDeviceDetect();

  const swiperSettings = {
    navigation: {
      nextEl: ".search_cards__buttons_next",
      prevEl: ".search_cards__buttons_prev",
    },
    slidesPerView: 1,
  };

  if (isMobile) {
    return cards.map((card) => <SearchCard card={card} key={card.title} />);
  }

  return (
    <div className="search_cards">
      <div className="search_cards__buttons">
        <button type="button" className="search_cards__buttons_prev">
          <IconArrowCircleMd width="31" fill="none" stroke="#2D2927" />
        </button>
        <button type="button" className="search_cards__buttons_next">
          <IconArrowCircleMd width="31" fill="none" stroke="#2D2927" />
        </button>
      </div>
      <Swiper navigation={swiperSettings.navigation} slidesPerView={swiperSettings.slidesPerView}>
        {cards.map((card) => (
          <SwiperSlide key={card.title}>
            <SearchCard card={card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

SearchCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.any),
};

export default SearchCards;
