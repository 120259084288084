import { useContext, useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import StoreContext from "../context/store";
import { useProductData2 } from "../context/products";

import { useCurrentCountryCode, usePriceInfo } from "../hooks/usePrices";

import { ComponentType } from "../constants/ComponentType";
import { Country } from "../constants/Country";
import { ALSkeleton } from "./ALComponents";
import Gallery from "./ProductPage/Gallery";
import ProductSummary from "./ProductPage/ProductSummary";
import ProductDetails from "./ProductPage/Details/ProductDetails";

import "../templates/product/product.scss";
import "./productDetailsContent/styles.scss";

function ProductDetailsContent({ productHandle, productLinkToPDP, componentType }) {
  const context = useContext(StoreContext);
  const countryCode = useCurrentCountryCode();
  const { data: productData } = useProductData2([productHandle], "long", countryCode);

  const [variantSelectedOptions, setVariantSelectedOptions] = useState({});
  const [product, setProduct] = useState();

  useEffect(() => {
    if (productData[0]) {
      const newProduct = {
        ...productData[0]?.node,
        ...productData[0]?.contentful,
        ...productData[0]?.shopify,
      };
      setProduct(newProduct);

      const availableVariant = newProduct.variants.find((variant) => variant.availableForSale)
        ?.selectedOptions[0];

      if (!availableVariant) {
        setVariantSelectedOptions({});
      } else {
        setVariantSelectedOptions({ [availableVariant.name]: availableVariant.value });
      }
    }
  }, [productData]);

  const [{ data: prices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [productHandle],
    tags: { [productHandle]: product?.tags },
    shouldFetchPrice: true,
  });

  const handleVariantChange = (event) => {
    const { target } = event;
    setVariantSelectedOptions((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const selectedVariant =
    variantSelectedOptions &&
    product?.variants?.find(
      (x) => x.title === variantSelectedOptions[Object.keys(variantSelectedOptions)[0]]
    );

  if (!product) {
    return null;
  }

  return (
    <div
      className={`container_products ${
        componentType === ComponentType.QUICKVIEW ? "quickview" : "byob_product_container"
      }`}
    >
      {!product?.images ? (
        <div className="pdp_popup_image_skeleton">
          <ALSkeleton />
        </div>
      ) : (
        <Gallery
          images={product?.images}
          videoId={product?.videoId || null}
          isUs={countryCode === Country.US}
          className={
            componentType === ComponentType.QUICKVIEW ? "gallery__quickview" : "gallery__byob"
          }
          componentType={componentType}
        />
      )}

      <div
        className={`container_products_details ${
          componentType === ComponentType.QUICKVIEW
            ? "quickview_product_details"
            : "byob_product_details"
        }`}
      >
        <ProductSummary
          product={product}
          countryCode={countryCode}
          selectedVariant={selectedVariant}
          variantSelectedOptions={variantSelectedOptions}
          componentType={componentType}
          onScrollToReviews={null}
          onVariantOptionChange={handleVariantChange}
          prices={prices}
          pricesLoading={pricesLoading}
        />

        <ProductDetails
          productHandle={productHandle}
          countryCode={countryCode}
          details={product?.productDetails}
          description={product?.description}
          title={product?.title}
          componentType={componentType}
          showProductCare={false}
          productLinkToPDP={productLinkToPDP}
          closeQuickView={context.closeQuickView}
          isPreOrder={product.isPreOrder}
        />
      </div>
    </div>
  );
}

ProductDetailsContent.propTypes = {
  productHandle: PropTypes.string.isRequired,
  productLinkToPDP: PropTypes.string,
  componentType: PropTypes.oneOf([ComponentType.BYOB_PDP, ComponentType.QUICKVIEW]),
};

export default withALErrorBoundary({
  name: "ProductDetailsContent",
  priority: "P2",
})(ProductDetailsContent);
