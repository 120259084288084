import PropTypes from "prop-types";

import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useShippingMessage } from "../../hooks/useShippingInfo";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import Item from "./productUsp/Item";

import IconHappyStar from "../icons/svgs/happy-star.svg";
import IconDuties from "../icons/svgs/duties.svg";
import IconQuality from "../icons/svgs/quality.svg";
import IconShipping from "../icons/svgs/shipping.svg";
import IconWarranty from "../icons/svgs/warranty.svg";
import IconHighDemand from "../icons/svgs/eye-high-demand.svg";
import IconEmail from "../icons/svgs/email.svg";

import * as Styles from "./productUsp/styles.module.scss";

const COUNTRIES_DT_INCLUDED = [
  "CA",
  "AU",
  "UK",
  "FR",
  "DE",
  "JP",
  "HK",
  "SG",
  "KR",
  "TW",
  "NL",
  "IE",
  "IT",
  "BE",
  "UAE",
];

function ProductUsp({ countryCode, isPreOrder }) {
  const dict = useLocalizedSentenceDict();
  const { isMobile } = useDeviceDetect();
  const iconSize = isMobile ? "28" : "30";

  return (
    <div className={`${Styles.productUspContainer} product-usp-container`}>
      {isPreOrder && (
        <>
          {/* Delivery updates */}
          <Item
            icon={<IconHighDemand width={iconSize} />}
            content={dict.get("High-demand style")}
          />

          {/* High demand style */}
          <Item
            icon={<IconEmail width={iconSize} stroke="#2D2927" fill="none" />}
            content={dict.get("Delivery updates straight to your inbox")}
          />

          {/* Warranty */}
          <Item
            icon={<IconWarranty width={iconSize} stroke="#2D2927" />}
            content={dict.get("2-Year Warranty")}
          />
        </>
      )}
      {!isPreOrder && (
        <>
          {/* Shipping & Returns */}
          <Item
            icon={<IconShipping width={iconSize} stroke="#2D2927" />}
            content={useShippingMessage()}
          />

          {/* Duties & Taxes */}
          {COUNTRIES_DT_INCLUDED.includes(countryCode) && (
            <Item
              icon={<IconDuties width={iconSize} stroke="#2D2927" />}
              content="Duties & Taxes Included"
            />
          )}

          {/* Quality */}
          <Item
            icon={<IconQuality width={iconSize} stroke="#2D2927" />}
            content={dict.get("Tarnishing Resistant & Hypoallergenic")}
          />

          {/* Warranty */}
          <Item
            icon={<IconWarranty width={iconSize} stroke="#2D2927" />}
            content={dict.get("2-Year Warranty")}
          />

          {/* Happy Customers */}
          <Item
            icon={<IconHappyStar width={iconSize} stroke="#2D2927" />}
            content={dict.get("1m+ happy customers")}
          />
        </>
      )}
    </div>
  );
}

ProductUsp.propTypes = {
  countryCode: PropTypes.string.isRequired,
};

export default withALErrorBoundary({
  name: "ProductUsp",
  priority: "P3",
})(ProductUsp);
