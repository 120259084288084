import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useTracking } from "../../context/Tracking";
import { ComponentType } from "../../constants/ComponentType";
import { ALLink } from "../ALComponents";
import { isBrowser } from "../../context/helpers";

import * as productAlternativeStyles from "./styles.module.scss";

function ProductAlternativeColorProduct({
  isActive,
  colorCode,
  colorName,
  activeProductHandle,
  activeProductColor,
  handle,
  availableForSale,
  componentType,
  handleAlternativeClick,
}) {
  const { trackClickAlternative } = useTracking();

  const alternativeClick = () => {
    trackClickAlternative({
      page: componentType,
      activeProductHandle,
      activeProductColor,
      alternativeHandle: handle,
      alternativeColor: colorName,
    });

    if (componentType === ComponentType.CP) {
      handleAlternativeClick(handle);
    }
  };

  let bubbleColor = colorCode || "transparent";
  if (colorCode.includes("/")) {
    bubbleColor = `linear-gradient( 90deg, ${colorCode.split("/")[0]}, ${
      colorCode.split("/")[0]
    } 50%, ${colorCode.split("/")[1]} 50% )`;
  }

  const whiteClear =
    colorName.toLowerCase() === "white" ||
    colorName.toLowerCase() === "clear" ||
    bubbleColor.includes("#FFFFFF");

  return (
    <span
      key={`alternative-${handle}-color`}
      className={`${productAlternativeStyles.bubbleContainer} ${
        isActive ? productAlternativeStyles.bubbleContainerActive : ""
      }`}
    >
      <span className={`${isActive ? productAlternativeStyles.bubbleContainerActiveBorder : ""}`}>
        <span
          className={`${
            !availableForSale ? productAlternativeStyles.bubbleContainerUnavailable : ""
          }`}
        />
        {componentType === ComponentType.PDP && (
          <ALLink
            to={`/products/${handle}`}
            onClick={alternativeClick}
            className={`${productAlternativeStyles.bubble} ${
              whiteClear ? productAlternativeStyles.white_bubble : ""
            }`}
            style={{ background: bubbleColor }}
            title={colorName || ""}
          >
            &nbsp;
          </ALLink>
        )}
        {componentType === ComponentType.PDP_ADS && (
          <ALLink
            to={`/p/${handle}/${isBrowser ? window.location.search : ""}`}
            onClick={alternativeClick}
            className={`${productAlternativeStyles.bubble} ${
              whiteClear ? productAlternativeStyles.white_bubble : ""
            }`}
            style={{ background: bubbleColor }}
            title={colorName || ""}
          >
            &nbsp;
          </ALLink>
        )}
        {componentType === ComponentType.CP && (
          <button
            type="button"
            className={`${productAlternativeStyles.bubble} ${
              whiteClear ? productAlternativeStyles.white_bubble : ""
            }`}
            style={{ background: bubbleColor }}
            onClick={alternativeClick}
            title={colorName || ""}
          >
            &nbsp;
          </button>
        )}
        {componentType === ComponentType.CP_LAZY && (
          <a
            href={`/products/${handle}`}
            className={`${productAlternativeStyles.bubble} ${
              whiteClear ? productAlternativeStyles.white_bubble : ""
            }`}
            style={{ background: bubbleColor }}
            title={colorName || ""}
          >
            <span className={productAlternativeStyles.colorName}>{colorName}</span>
            &nbsp;
          </a>
        )}
      </span>
    </span>
  );
}

ProductAlternativeColorProduct.propTypes = {
  isActive: PropTypes.bool.isRequired,
  colorCode: PropTypes.string,
  colorName: PropTypes.string,
  activeProductHandle: PropTypes.string.isRequired,
  activeProductColor: PropTypes.string,
  handle: PropTypes.string.isRequired,
  availableForSale: PropTypes.bool.isRequired,
  componentType: PropTypes.string.isRequired,
  handleAlternativeClick: PropTypes.func,
};

function ProductAlternativeColor({
  pageProductHandle,
  alternatives = [],
  componentType,
  handleAlternativeClick,
}) {
  const [sortedAlternatives, setSortedAlternatives] = useState(alternatives);

  useEffect(() => {
    const sortedAlternativesCopy = [...alternatives];
    // Order alternatives: gold first, alphabetical after
    sortedAlternativesCopy.sort((a, b) => {
      /* eslint-disable no-unused-vars */
      const [_aColorCode, aColorName] = a.attrArgs;
      /* eslint-disable no-unused-vars */
      const [_bColorCode, bColorName] = b.attrArgs;
      if (aColorName === "gold") {
        return -1;
      }
      return aColorName < bColorName ? -1 : 1;
    });
    setSortedAlternatives(sortedAlternativesCopy);
  }, [alternatives]);

  const componentContainerClassName = `container_${componentType.toLowerCase()}`;
  const componentWrapperClassName = `wrapper_${componentType.toLowerCase()}`;

  if (!sortedAlternatives?.length) return null;

  return (
    <div
      className={`${productAlternativeStyles.container} ${productAlternativeStyles[componentContainerClassName]}
       product_alt_bubble_container`}
    >
      <div className="mb-5">
        {componentType !== ComponentType.CP && (
          <>
            <span className="pr-10 text text--bold">Color</span>
            <span className="text">
              {sortedAlternatives.find((a) => a.handle === pageProductHandle)?.attrArgs[1] || ""}
            </span>
          </>
        )}
      </div>
      <div
        className={`${productAlternativeStyles.wrapper} ${productAlternativeStyles[componentWrapperClassName]}`}
      >
        {sortedAlternatives
          .filter((a) => a.isFound)
          .map((a) => {
            const [colorCode, colorName] = a.attrArgs;
            return (
              <ProductAlternativeColorProduct
                key={a.handle}
                isActive={pageProductHandle === a.handle}
                colorCode={colorCode}
                colorName={colorName}
                handle={a.handle}
                availableForSale={a.availableForSale}
                activeProductHandle={pageProductHandle}
                activeProductColor={
                  sortedAlternatives.find((pa) => pa.handle === pageProductHandle)?.colorName
                }
                componentType={componentType}
                handleAlternativeClick={handleAlternativeClick}
              />
            );
          })}
      </div>
    </div>
  );
}

ProductAlternativeColor.propTypes = {
  pageProductHandle: PropTypes.string.isRequired,
  alternatives: PropTypes.arrayOf(
    PropTypes.shape({
      attrArgs: PropTypes.arrayOf(PropTypes.string).isRequired,
      isFound: PropTypes.bool.isRequired,
      handle: PropTypes.string.isRequired,
      attrName: PropTypes.string.isRequired,
      availableForSale: PropTypes.bool.isRequired,
    })
  ),
  componentType: PropTypes.string.isRequired,
  handleAlternativeClick: PropTypes.func,
};

export default withALErrorBoundary({
  name: "ProductAlternativeColor",
  priority: "P2",
})(ProductAlternativeColor);
