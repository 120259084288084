import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentCountryCode } from "../../../hooks/usePrices";
import { Country } from "../../../constants/Country";
import MenuContainer from "./menu/MenuContainer";

import "./menu/styles.scss";

function Menu({ menu, showMenu }) {
  const { title: menuTitle, textColor: color, menuContainer } = menu;
  const { id, menuCategories, imageLinks } = menuContainer[0];
  const [isExpanded, setIsExpanded] = useState(false);
  const isSingleMenuItem = menuCategories?.length === 1 && menuCategories[0].links.length === 1;
  const countryCode = useCurrentCountryCode();
  const isUSCountry = countryCode === Country.US;
  const [filteredImageLinks, setFilteredImageLinks] = useState([]);

  const onMenuClick = () => {
    if (isSingleMenuItem) {
      const [item] = menuCategories[0].links;
      const destinationUrl = item.title === "empty" ? menuCategories[0].titleLink : item.link.link;
      navigate(destinationUrl);
    }
  };

  useEffect(() => {
    const newFilteredImageLinks = imageLinks?.filter((imageLink) => {
      if (isUSCountry && imageLink.title === "AL Rewards") {
        return false;
      }
      if (!isUSCountry && imageLink.title === "AL Luxe") {
        return false;
      }
      return true;
    });
    setFilteredImageLinks(newFilteredImageLinks);
  }, [countryCode, imageLinks]);

  return (
    <div className="header_left_menu_item__container" onMouseEnter={showMenu}>
      <button
        className={`header_left_menu_item header_left_menu_item_${menuTitle
          .replace(" ", "")
          .toLowerCase()}`}
        aria-expanded={isExpanded}
        style={{ color, textDecorationColor: color }}
        type="button"
        onMouseEnter={() => setIsExpanded(!isSingleMenuItem)}
        onMouseLeave={() => setIsExpanded(false)}
        onFocus={() => setIsExpanded(!isSingleMenuItem)}
        onBlur={() => setIsExpanded(false)}
        onClick={onMenuClick}
      >
        <h3 className="microtext microtext--bold microtext--uc" style={{ color }}>
          {menuTitle}
        </h3>
      </button>
      <div
        className={`header_left_menu_content navMenu__containers ${
          filteredImageLinks && filteredImageLinks.length <= 3
            ? "header_left_menu_content__short"
            : ""
        }`}
        key={menu.id}
        aria-expanded={isExpanded}
        role="menuitem"
        onMouseEnter={() => setIsExpanded(!isSingleMenuItem)}
        onMouseLeave={() => setIsExpanded(false)}
        onFocus={() => setIsExpanded(!isSingleMenuItem)}
        onBlur={() => setIsExpanded(false)}
        tabIndex="-1"
      >
        <MenuContainer
          key={id}
          categories={menuCategories}
          imageLinks={filteredImageLinks}
          menuTitle={menuTitle}
          closeMenu={() => setIsExpanded(false)}
        />
      </div>
    </div>
  );
}

Menu.propTypes = {
  showMenu: PropTypes.func,
  menu: PropTypes.shape({
    id: PropTypes.string,
    textColor: PropTypes.string,
    title: PropTypes.string,
    menuContainer: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        imageLinks: PropTypes.array,
        menuCategories: PropTypes.array,
      })
    ),
  }),
};

export default withALErrorBoundary({
  name: "Menu",
  priority: "P2",
})(Menu);
