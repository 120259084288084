import { useContext, useMemo } from "react";
import { navigate } from "gatsby";
import { format, parseISO } from "date-fns";

import { GeoContext } from "../context/GeoProvider";
import { DiscountContext } from "../context/DiscountProvider";
import { useAddToCartWithLuxe } from "../context/LuxeProvider";
import { getCookie } from "../context/helpers";
import { applyIgPrices } from "../helpers/intelligems";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { Country } from "../constants/Country";
import { InventoryPolicies } from "../constants/InventoryPolicies";

const useCart = (product, productPrices) => {
  const fullProduct = useMemo(
    () => ({ ...product.node, ...product.contentful.node }),
    [product.node, product.contentful]
  );
  const { sendReport } = useALError();
  const { discountInfo } = useContext(DiscountContext);
  const { gePriceDetails } = useContext(GeoContext);
  const addToCartWithLuxe = useAddToCartWithLuxe();

  const igPrices = applyIgPrices(fullProduct.id, fullProduct.variants[0], productPrices);

  const isPreOrder =
    fullProduct.isPreOrder &&
    fullProduct.variants[0]?.inventoryPolicy === InventoryPolicies.CONTINUE;

  const shippingDate = isPreOrder
    ? (() => {
        try {
          const parsedDate = parseISO(fullProduct.preOrderShippingDate);
          return format(parsedDate, "MMM d, yyyy");
        } catch (error) {
          console.error(error);
          return null;
        }
      })()
    : null;

  const quickAddToCart = async () => {
    const { title, handle, images } = fullProduct;
    const price = parseFloat(igPrices.finalPrice);
    let compareAtPrice = parseFloat(igPrices.fullPrice);
    if (igPrices.finalPrice === igPrices.fullPrice) {
      compareAtPrice = 0;
    }

    const { sku, id: variantId } = fullProduct.variants[0];
    const isFinalSale = fullProduct?.tags?.includes("Final Sale");

    try {
      const dataProductToAdd = {
        quantity: 1,
        attributes: [],
        variant: {
          id: variantId,
          sku,
          compareAtPrice,
          price,
          product: {
            title,
            id: fullProduct.id,
            handle,
            productType: fullProduct.productType,
            images,
          },
        },
      };

      if (isPreOrder) {
        dataProductToAdd.attributes.push({ key: "_pre_order", value: "true" });
        dataProductToAdd.attributes.push({ key: "_ships_by_pre_order", value: shippingDate });
      }
      if (
        gePriceDetails?.CountryCode === Country.US &&
        productPrices &&
        "finalPrice" in productPrices &&
        "fullPrice" in productPrices
      ) {
        dataProductToAdd.attributes.push({
          key: "_customer_currency",
          value: productPrices.currencyCode,
        });
        dataProductToAdd.attributes.push({
          key: "_customer_discount_value",
          value:
            (
              parseFloat(productPrices?.fullPrice) - parseFloat(productPrices?.finalPrice)
            ).toString() || "",
        });
      }

      if (
        gePriceDetails?.CountryCode !== Country.US &&
        productPrices &&
        "_finalPrice" in productPrices &&
        "_fullPrice" in productPrices
      ) {
        dataProductToAdd.attributes.push({
          key: "_compare_at_price",
          value: productPrices?.fullPrice?.toString() || "",
        });
        dataProductToAdd.attributes.push({
          key: "_price",
          value: productPrices?.finalPrice?.toString() || "",
        });
        dataProductToAdd.attributes.push({
          key: "_discount_code",
          value: discountInfo?.code || "",
        });
      }

      const impactClickId = getCookie("_irclickid");
      if (impactClickId) {
        dataProductToAdd.attributes.push({ key: "_irclickid", value: impactClickId });
      }

      if (isFinalSale) {
        dataProductToAdd.attributes.push({ key: "_final_sale", value: "true" });
      }

      if (igPrices.isIgPrice) {
        dataProductToAdd.attributes.push(
          {
            key: "_ig_full_price",
            value: igPrices.fullPrice,
          },
          {
            key: "_ig_final_price",
            value: igPrices.finalPrice,
          },
          {
            key: "_ig_compareAt_price",
            value: igPrices.compareAtPrice,
          }
        );
      }

      await addToCartWithLuxe({ dataProductsToAdd: [dataProductToAdd] });
    } catch (error) {
      sendReport(error, { name: "useCart", priority: "P1" });
      if (fullProduct?.handle) {
        navigate(`/products/${fullProduct.handle}`);
      }
    }
  };

  return { quickAddToCart };
};

export default useCart;
