import { useState } from "react";
import { ALSkeleton } from "../ALComponents";
import * as Styles from "./imagesContainer.module.scss";

function ImagesContainer({ product }) {
  const [isImageLoaded, updateIsImageLoaded] = useState(null);

  return (
    <div className={Styles.images}>
      {isImageLoaded == null || isImageLoaded == true ? (
        <>
          {!isImageLoaded ? <ALSkeleton /> : null}
          <div className={Styles.image}>
            <img
              onLoad={() => updateIsImageLoaded(true)}
              src={product.images[0].url}
              alt={product.images[0].altText}
            />
          </div>
        </>
      ) : null}
      <div className={Styles.image_hover}>
        <img src={product.images[1].url} alt={product.images[1].altText} />
      </div>
    </div>
  );
}

export default ImagesContainer;
