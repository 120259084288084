import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { isBrowser, hasLoyaltyLion } from "../context/helpers";
import { DiscountContext } from "../context/DiscountProvider";
import { useCurrentCountryCode } from "./usePrices";

export function useAuthenticateLL(email) {
  const countryCode = useCurrentCountryCode();
  useEffect(() => {
    if (isBrowser) {
      if (hasLoyaltyLion() && email) {
        fetch(
          `/.netlify/functions/loyalty-lion-auth?email=${encodeURIComponent(
            email
          )}&countryCode=${countryCode}`
        )
          .then((res) => res.json())
          .then((json) => {
            window.loyaltylion.authenticateCustomer(json.userObject);
          })
          .catch((err) => console.error(err));
      }
    }
  }, [email, countryCode]);
}

export function useMemberTiers() {
  const { isDoublePoints } = useContext(DiscountContext);
  const tiers = useMemo(() => {
    const result = [
      {
        id: "silver",
        title: "Silver",
        level: "Silver",
        cashback: "10%",
        points: 1500,
        pointsPerDollar: 10,
        perkItems: {
          doublePoints: true,
          earlySale: true,
          birthdayReward: true,
          newProducts: false,
        },
      },
      {
        id: "gold",
        title: "Gold",
        level: "Gold",
        cashback: "13%",
        points: 1750,
        pointsPerDollar: 10,
        perkItems: {
          doublePoints: true,
          earlySale: true,
          birthdayReward: true,
          newProducts: false,
        },
      },
      {
        id: "platinium",
        title: "Platinum",
        level: "Platinum",
        cashback: "20%",
        perks: "Free Gift",
        points: 2250,
        pointsPerDollar: 10,
        perkItems: {
          doublePoints: true,
          earlySale: true,
          birthdayReward: true,
          newProducts: true,
        },
      },
      {
        id: "diamond",
        title: "Diamond",
        level: "Diamond",
        cashback: "25%",
        perks: "Free Gift",
        points: 2750,
        pointsPerDollar: 10,
        perkItems: {
          doublePoints: true,
          earlySale: true,
          birthdayReward: true,
          newProducts: true,
        },
      },
    ];

    if (isDoublePoints) result.forEach((tier) => (tier.pointsPerDollar *= 2));

    return result;
  }, [isDoublePoints]);

  return tiers;
}

export function useUserTier(contentUpdateTicker) {
  const [userTierIndex, setUserTierIndex] = useState(0);
  const tierWidgetRef = useRef(null);
  const tiers = useMemberTiers();

  const userTier = tiers[userTierIndex];
  const nextTier = tiers[userTierIndex + 1];

  useEffect(() => {
    if (!tierWidgetRef?.current?.getElementsByClassName("lion-tier-box--current")?.length) {
      return;
    }
    const userTierElement =
      tierWidgetRef.current.getElementsByClassName("lion-tier-box--current")[0];
    const retrievedUserTierIndex =
      userTierElement.getElementsByClassName("lion-tier-box__position")?.[0]?.textContent;
    const newUserTierIndex = (retrievedUserTierIndex || 0) - 1;

    if (newUserTierIndex < 0 || newUserTierIndex > tiers.length) {
      console.error("Tier not found", newUserTierIndex);
      setUserTierIndex(0);
    } else {
      setUserTierIndex(newUserTierIndex);
    }
  }, [tiers, contentUpdateTicker]);

  return [userTier, nextTier, tierWidgetRef];
}
export function usePoints() {
  const ref = useRef(null);
  const [points, setPoints] = useState(null);

  useEffect(() => {
    function updatePoints() {
      if (ref.current.textContent) {
        setPoints(parseInt(ref.current.textContent.replace(",", ""), 10));
      }
    }
    if (isBrowser && ref.current) {
      const observer = new MutationObserver(() => {
        updatePoints();
      });
      observer.observe(ref.current, { attributes: false, childList: true });
      updatePoints();
      return () => observer.disconnect();
    }
  }, []);
  return [points, ref];
}
