import { useContext } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";

import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { useAuth } from "../context/AuthProvider";
import { LuxeContext } from "../context/LuxeProvider";
import { ALButton } from "./ALComponents";
import { Link } from "./al_components/ALLink";

import "./accountOverview/styles.scss";

function AccountOverview({ points, closeMenu = () => {} }) {
  const { accountDetails, logout } = useAuth();
  const dict = useLocalizedSentenceDict();
  const { luxe } = useContext(LuxeContext);
  const { subscriptionCredit, isLuxeActive } = luxe;
  const { isMobile } = useDeviceDetect();

  const welcomeMessage = `Welcome Back${
    accountDetails?.firstName ? `, ${accountDetails?.firstName}!` : "!"
  }`;
  const memberText = isLuxeActive ? "AL LUXE MEMBER" : "AL REWARDS MEMBER";
  const quickAccessPages = [
    { label: "My Rewards", link: "/rewards" },
    { label: "Wish List", link: "/pages/wishlist" },
    { label: "Order History", link: "/order-history" },
    { label: "Account Details", link: "/account" },
  ];
  if (isLuxeActive) {
    quickAccessPages[0] = { label: "AL Luxe", link: "/ana-luisa-luxe" };
  }

  const goToAccount = () => {
    navigate("/account/");
    closeMenu();
  };

  let accountButtonVariant = isLuxeActive ? "secondary" : "primary";
  if (!isMobile) {
    accountButtonVariant = "primary";
  }

  return (
    <div
      key="loggedin"
      className={`container-login ${
        isLuxeActive ? "luxe-container-color" : "rewards-container-color"
      }`}
    >
      <p className={`welcome-message ${isLuxeActive ? "luxe-message-color" : ""}`}>
        {welcomeMessage}
      </p>
      <div
        className={`member-details ${
          isLuxeActive ? "luxe-member-details-color" : "rewards-member-details-color"
        }`}
      >
        <p
          className={`member-text ${
            isLuxeActive ? "luxe-member-text-color" : "rewards-member-text-color"
          }`}
        >
          {memberText}
        </p>
        {isLuxeActive ? (
          <div className={`available ${isLuxeActive ? "luxe-available-color" : ""}`}>
            <span className="bold">${subscriptionCredit}</span>&nbsp;
            <span>available to spend</span>
          </div>
        ) : (
          <div className="available">
            <span className="bold">{points} POINTS</span>&nbsp;
            <span>available to redeem</span>
          </div>
        )}
      </div>
      <div className={`quick-access-wrapper ${isLuxeActive ? "luxe-quick-access-color" : ""}`}>
        <ul>
          {quickAccessPages.map((page, index) => (
            <li key={index}>
              <Link to={page.link}>{dict.get(page.label)}</Link>
            </li>
          ))}
          <li>
            <button
              className={`logout-button ${isLuxeActive ? "luxe-logout-button" : ""}`}
              onClick={logout}
              type="button"
            >
              {dict.get("Log out")}
            </button>
          </li>
        </ul>
      </div>
      <ALButton variant={accountButtonVariant} onClick={goToAccount}>
        View account
      </ALButton>
    </div>
  );
}

AccountOverview.propTypes = {
  points: PropTypes.string.isRequired,
  closeMenu: PropTypes.func,
};

export default AccountOverview;
