import { useCallback, useContext } from "react";
import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { DiscountContext } from "../../context/DiscountProvider";
import { useAddToCartWithLuxe } from "../../context/LuxeProvider";

import useUpSellProducts from "../../hooks/useUpSellProducts";
import { useLocalizedProductsWithDiscount } from "../../hooks/useLocalizedCartInfo";
import { useTracking } from "../../context/Tracking";
import { getCookie } from "../../context/helpers";

import UpSells from "./UpSells";

function UpSellsRecommendation({ lines = [] }) {
  const { discountInfo } = useContext(DiscountContext);
  const { trackRecommendedProduct } = useTracking();
  const {
    data: recommendedProducts,
    isLoading,
    id: recommendationId,
  } = useUpSellProducts(lines, discountInfo);
  const products = useLocalizedProductsWithDiscount(recommendedProducts);
  const addToCartWithLuxe = useAddToCartWithLuxe();

  const handleAddToCart = useCallback(
    async (product) => {
      trackRecommendedProduct("add", "Side Cart", {
        productTitle: product?.title,
        productCategory: product?.category,
        productId: product?.id,
        recommendationId,
      });

      const variant = product.variants[0];
      const dataProductToAdd = {
        quantity: 1,
        attributes: [],
        variant: {
          id: variant.id,
          sku: variant.sku,
          price: variant.price,
          compareAtPrice: variant.compareAtPrice,
          product: {
            title: product.title,
            id: product.id,
            handle: product.handle,
            productType: product.productType,
            images: product.images,
          },
        },
      };
      const isFinalSale = product.tags?.includes("Final Sale");
      if (isFinalSale) {
        dataProductToAdd.attributes.push({ key: "_final_sale", value: "true" });
      }
      const impactClickId = getCookie("_irclickid");
      if (impactClickId) {
        dataProductToAdd.attributes.push({ key: "_irclickid", value: impactClickId });
      }
      await addToCartWithLuxe({ dataProductsToAdd: [dataProductToAdd] });
    },
    [addToCartWithLuxe, recommendationId, trackRecommendedProduct]
  );

  return (
    <UpSells
      title="You may also like"
      products={products}
      isLoading={isLoading}
      handleAddToCart={handleAddToCart}
      slidesPerView={1.3}
    />
  );
}

UpSellsRecommendation.propTypes = {
  lines: PropTypes.array,
};

export default withALErrorBoundary({
  name: "UpSellsRecommendation",
  priority: "P3",
})(UpSellsRecommendation);
