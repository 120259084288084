import { useState } from "react";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { isBrowser, validateEmail } from "../../context/helpers";
import { ALButton } from "../ALComponents";
import IconClose from "../icons/svgs/close.svg";

function NotifyMe({ errorMessage, isPreOrder, title, variant, productId, sendReport }) {
  const dict = useLocalizedSentenceDict();
  const [openNotifyMe, setOpenNotifyMe] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState("");
  const [formError, setFormError] = useState("");
  const [registered, updateRegistered] = useState(false);
  const closeNotifyModal = () => setOpenNotifyMe(false);

  const subscribeBackInStock = () => {
    if (isBrowser) {
      if (validateEmail(notifyEmail)) {
        try {
          // Trigger Exponea pixel
          if (window.exponea.identified != "true") {
            window.exponea.identify(
              {
                email_id: notifyEmail,
              },
              {
                Email: notifyEmail,
              }
            );
          }

          const exponeaVariant = {
            product_id: productId.replace("gid://shopify/Product/", ""),
            variant_id: variant.shopifyId.replace("gid://shopify/ProductVariant/", ""),
            title,
            price: variant.price,
          };
          if (variant.compareAtPrice) {
            exponeaVariant.compareAtPrice = variant.compareAtPrice;
          }
          window.exponea.track("back_in_stock", exponeaVariant);
          updateRegistered(true);
        } catch (error) {
          console.log("back_in_stock");
          console.log(error);
          sendReport(error, { name: "ButtonsAddToCart", priority: "P1" });
        }
      } else {
        setFormError("Please enter a valid email address!");
      }
    }
  };

  return (
    <>
      {errorMessage ? <p className="add_to_cart_error al_p">{errorMessage}</p> : null}
      <div className={`add_to_cart ${isPreOrder ? "add_to_cart--preorder" : ""}`}>
        <ALButton
          size="large"
          variant={!isPreOrder ? "primary" : "secondary"}
          fullWidth
          onClick={() => setOpenNotifyMe((o) => !o)}
        >
          {dict.get("NOTIFY ME WHEN AVAILABLE")}
        </ALButton>
      </div>
      <Popup open={openNotifyMe} closeOnDocumentClick onClose={closeNotifyModal}>
        <div className="notify_me_container">
          <div className="notify_me_container--close" onClick={closeNotifyModal}>
            <IconClose width="30" fill="#2D2927" />
          </div>
          <div className="notify_me_content">
            <p className="notify_me_content_title al_h4">{title?.split(" - ")[1] || title}</p>
            <p className="al_p">
              {dict.get("Register to receive a notification when this item comes back in stock.")}
            </p>
            <div className="notify_me_form">
              {registered ? (
                <p className="al_p" style={{ color: "green", marginTop: "50px" }}>
                  {dict.get(`You're in! We'll let you know when it's back in stock.`)}
                  <span
                    onClick={closeNotifyModal}
                    className="al_p--underline"
                    style={{ cursor: "pointer" }}
                  >
                    {dict.get("Close")}
                  </span>
                </p>
              ) : (
                <>
                  <p className="notify_me_form--error al_p">{formError != "" ? formError : " "}</p>
                  <input
                    className="notify_me_form--input al_p"
                    type="email"
                    placeholder={dict.get("Enter email")}
                    value={notifyEmail}
                    onChange={(e) => {
                      if (formError != "") {
                        setFormError("");
                      }
                      setNotifyEmail(e.target.value);
                    }}
                  />

                  <ALButton size="large" fullWidth onClick={subscribeBackInStock}>
                    {dict.get("NOTIFY ME WHEN AVAILABLE")}
                  </ALButton>
                </>
              )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}

NotifyMe.propTypes = {
  errorMessage: PropTypes.string,
  isPreOrder: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.object,
  productId: PropTypes.string,
  sendReport: PropTypes.func,
};

export default withALErrorBoundary({
  name: "NotifyMe",
  priority: "P1",
})(NotifyMe);
