import PropTypes from "prop-types";
import { useContext } from "react";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { CartContext } from "../../../context/CartProvider";
import IconTrash from "../../icons/svgs/trash.svg";
import ArrowIcon from "../../icons/svgs/arrow.svg";
import * as Styles from "./actions/styles.module.scss";

function Actions({ lineItem, isFreeGift, onRemove }) {
  const { addSingleProductToCart: addProductToCart } = useContext(CartContext);

  const upperQtyLimit = Math.max(11, lineItem.quantity + 1);
  const qtyOptionsArray = [...Array(upperQtyLimit).keys()].slice(1);
  const canUpdateQuantity = !lineItem?.attributes?.find((e) => e.key === "_engravable_font");

  const onUpdateQuantity = (qty) => {
    const { variant } = lineItem;
    const dataProductToAdd = {
      id: lineItem.id,
      quantity: qty,
      attributes: lineItem.attributes,
      variant: {
        id: variant.id,
        sku: variant.sku,
        price: variant.price?.amount,
        compareAtPrice: variant.compareAtPrice,
        product: {
          title: variant.title,
          id: variant.id,
        },
      },
    };

    addProductToCart({ dataProductToAdd });
  };

  if (isFreeGift) {
    return null;
  }

  return (
    <div className={Styles.actions}>
      <div className={Styles.actionsQuantity}>
        {canUpdateQuantity && (
          <>
            <select
              value={lineItem.quantity}
              onChange={(e) => onUpdateQuantity(parseInt(e.target.value, 10))}
              data-testid="actions-select"
            >
              {qtyOptionsArray.map((e) => (
                <option key={`quantity-select-${e}`} value={e}>
                  {e}
                </option>
              ))}
            </select>
            <div className={Styles.actionsQuantityArrow}>
              <ArrowIcon width="8" height="14" fill="#2D2927" />
            </div>
          </>
        )}
      </div>

      <button
        className={Styles.remove_button}
        type="button"
        data-testid="actions-button"
        onClick={() => {
          onRemove(lineItem.id);
        }}
      >
        <IconTrash width="15" height="21" fill="none" stroke="#2D2927" />
      </button>
    </div>
  );
}

Actions.propTypes = {
  lineItem: PropTypes.object,
  isFreeGift: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default withALErrorBoundary({
  name: "Actions",
  priority: "P2",
})(Actions);
