export function CalculatePrice(
  value,
  currencyConversionRate,
  countryCoefficientRate,
  vatRateTypes,
  isGrossPrices,
  currencyDecimalPlaces,
  productCountryVatRate,
  roundingRules
) {
  if (value == 0) {
    return (0).toFixed(currencyDecimalPlaces);
  }

  const merchantVatRate = vatRateTypes.LocalVATRateType.Rate / 100;
  let countryVatRate = vatRateTypes.VATRateType.Rate / 100;
  if (productCountryVatRate || productCountryVatRate === 0) {
    if (productCountryVatRate == 0) {
      countryVatRate = 0;
    } else {
      countryVatRate = productCountryVatRate / 100;
    }
  }

  if (isGrossPrices) {
    if (
      vatRateTypes.IncludeVATTypeId == 0 ||
      vatRateTypes.IncludeVATTypeId == 8 ||
      (vatRateTypes.IncludeVATTypeId == 6 && vatRateTypes.UseCountryVAT)
    ) {
      value /= 1 + merchantVatRate;
      if (vatRateTypes.IncludeVATTypeId == 6) {
        value += value * countryVatRate;
      }
    }
  } else if (
    vatRateTypes.IncludeVATTypeId == 2 ||
    vatRateTypes.IncludeVATTypeId == 4 ||
    vatRateTypes.IncludeVATTypeId == 6
  ) {
    if (vatRateTypes.UseCountryVAT) {
      value += value * countryVatRate;
    } else {
      value += value * merchantVatRate;
    }
  }

  value *= currencyConversionRate;
  value *= countryCoefficientRate;
  value = value.toFixed(currencyDecimalPlaces);

  if (roundingRules) {
    const ranges = roundingRules.RoundingRanges;
    let range = null;
    for (const r in ranges) {
      const rg = ranges[r];
      if (rg.From < value && value <= rg.To) {
        range = rg;
        break;
      }
    }

    if (range != null) {
      // convert range to form of absolute
      range = ConvertRoundingRangeToAbsolute(value, range);
      // apply logic of absolute range rounding
      value = AbsoluteRounding(value, range);
    }
  }
  if (value < 0) {
    value = 0;
  }
  return value;
}

export function ConvertRoundingRangeToAbsolute(price, range) {
  let result = null;
  if (range.RangeBehavior == 1) {
    // range already has absolute behavior
    result = range;
  } else {
    result = {};
    result.RangeBehavior = range.RangeBehavior;
    result.RoundingExceptions = [];
    result.From = range.From;
    result.To = range.To;
    const int_part = Math.floor(price);
    let base = null;
    if (range.RangeBehavior == 2) {
      // Relative Decimal
      result.LowerTarget = int_part - 1 + range.LowerTarget;
      result.UpperTarget = int_part + range.UpperTarget;
      result.Threshold = int_part + range.Threshold;

      for (const ex in range.RoundingExceptions) {
        range.RoundingExceptions[ex].ExceptionValue += int_part;
        result.RoundingExceptions.push(range.RoundingExceptions[ex]);
      }
    } else if (range.RangeBehavior == 3) {
      // Relative Whole
      if (range.TargetBehaviorHelperValue == 0) {
        range.TargetBehaviorHelperValue = 10;
      }
      base = Math.floor(price / range.TargetBehaviorHelperValue) * range.TargetBehaviorHelperValue;
      result.LowerTarget = base - range.TargetBehaviorHelperValue + range.LowerTarget;
      result.UpperTarget = base + range.UpperTarget;
      result.Threshold = base + range.Threshold;
      for (const ex2 in range.RoundingExceptions) {
        range.RoundingExceptions[ex2].ExceptionValue += base;
        result.RoundingExceptions.push(range.RoundingExceptions[ex2]);
      }
    } else if (range.RangeBehavior == 4) {
      // Nearest target
      if (range.TargetBehaviorHelperValue == 0) {
        range.TargetBehaviorHelperValue = 5;
      }
      base = Math.floor(price / range.TargetBehaviorHelperValue) * range.TargetBehaviorHelperValue;
      result.LowerTarget = base - 1 + range.LowerTarget;
      result.UpperTarget = base - 1 + range.TargetBehaviorHelperValue + range.UpperTarget;

      result.Threshold = base + range.Threshold;
      for (const ex3 in range.RoundingExceptions) {
        range.RoundingExceptions[ex3].ExceptionValue += base;
        result.RoundingExceptions.push(range.RoundingExceptions[ex3]);
      }
    }
  }

  return result;
}

export function AbsoluteRounding(price, range) {
  let result = null;
  // check exceptions
  if (range.RoundingExceptions.length > 0) {
    for (const e in range.RoundingExceptions) {
      const ex = range.RoundingExceptions[e];
      if (price == ex.ExceptionValue) {
        result = price;
      }
    }
  }
  // no exception was found
  if (!result) {
    // check threshold
    if (price < range.Threshold) {
      result = range.LowerTarget;
    } else {
      result = range.UpperTarget;
    }
  }
  return result;
}
