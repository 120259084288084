import publicIp from "public-ip";
import axios from "axios";
import { isBrowser, generateId, getCookie } from "../helpers";

export async function fbSendEvent(event, allData = {}, sendToConversionsAPI = true) {
  if (isBrowser) {
    const { userData = {}, ...data } = allData;

    let ip = null;

    if (userData && userData.client_ip) {
      ip = userData.client_ip;
      delete userData.client_ip;
    } else {
      try {
        ip = await publicIp.v4();
      } catch (error) {
        console.log("Error");
        console.log(error);
      }
    }

    const _fbc = getCookie("_fbc");
    if (_fbc) {
      userData.fbc = _fbc;
    }

    const _fbp = getCookie("_fbp");
    if (_fbp) {
      userData.fbp = _fbp;
    }

    const external_id = window.exponea?.configuration?.customer?.cookie || null;
    if (external_id) {
      userData.external_id = external_id;
    }

    let event_id = null;
    if (event === "Purchase") {
      event_id = data.event_id ?? `${event.toLowerCase()}.${data.orderId}`;
    } else {
      event_id = data.event_id ?? `${event.toLowerCase()}.${generateId(10)}`;
    }

    const hasFBQ = isBrowser && typeof window.fbq !== "undefined";
    if (hasFBQ) {
      if (event_id != null) {
        window.fbq("track", event, data, { eventID: event_id });
      } else {
        window.fbq("track", event, data);
      }
    }

    if (sendToConversionsAPI) {
      const dataPost = {
        ...data,
        client_ip: ip,
        userData,
        event_name: event,
        event_time: Math.floor(new Date() / 1000),
      };

      if (event_id != null) {
        dataPost.event_id = event_id;
      }

      axios.post("/.netlify/functions/events", dataPost).catch((err) => {
        console.error(err);
      });
    }
  }
}

export function fbSendUserSignup(hashedEmail, appendGlobalData) {
  fbSendEvent(
    "CompleteRegistration",
    appendGlobalData({
      content_name: hashedEmail,
    })
  );
}

export function fbSendAddToCart(
  { price, title, productId, category, currency, quantity },
  appendGlobalData
) {
  fbSendEvent(
    "AddToCart",
    appendGlobalData({
      content_type: "product_group",
      content_category: category,
      quantity,
      value: price,
      content_ids: [productId],
      currency,
      content_name: title,
    })
  );
}

export function fbSendViewProductPage({ price, title, productType, productId }, appendGlobalData) {
  const data = appendGlobalData({
    value: parseFloat(price),
    content_type: "product",
    content_category: productType,
    content_ids: [productId],
    content_name: title,
  });
  fbSendEvent("ViewContent", data);
}

export function fbSendPageView() {
  fbSendEvent("PageView");
}

export function fbSendAddToWishlist({ productId, productHandle, price }, appendGlobalData) {
  fbSendEvent(
    "AddToWishlist",
    appendGlobalData({
      content_type: "product_group",
      content_ids: [productHandle],
      value: price,
      contents: [
        {
          id: productId,
          quantity: 1,
        },
      ],
    })
  );
}
