import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { GeoContext } from "../../../context/GeoProvider";
import { gaCustomEvent, edgesToArray } from "../../../context/helpers";

import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";

import BrowseProducts from "../../blocks/BrowseProducts";
import MobileMenuButton from "./categoryMenu/MobileMenuButton";
import MobileCard from "./categoryMenu/MobileCard";
import { ALLink as Link } from "../../ALComponents";
import { ComponentType } from "../../../constants/ComponentType";

import "./categoryMenu/styles.scss";

function CategoryMenu({
  activateCategoryMenu,
  categoryMenuStatus,
  subMenuStatus,
  initializeMenuStatuses,
  closeMenu,
}) {
  const { gePriceDetails } = useContext(GeoContext);
  const categoryMenu = useStaticQuery(graphql`
    query CategoryMenu {
      menuWrapper: allContentfulMenuWrapper {
        edges {
          node {
            node_locale
            countries {
              code
            }
            mobileMenuItems {
              id
              node_locale
              title
              textColor
              backgroundColor
              useImageAsMenu
              image {
                mobileImage {
                  file {
                    url
                  }
                }
              }
              menuContainer {
                menuCategory {
                  id
                  title
                  links {
                    title
                    link {
                      id
                      link
                    }
                  }
                  titleLink
                  mobileMenuItems {
                    image {
                      altText
                      mobileImage {
                        gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
                      }
                    }
                    link {
                      title
                      link {
                        id
                        link
                      }
                    }
                  }
                }
              }
              productsCarousel {
                ... on ContentfulHomePageBrowseProducts {
                  __typename
                  contentful_id
                  title
                  collectionHandle
                }
              }
            }
          }
        }
      }
      allContentfulMobileMenuCard {
        edges {
          node {
            node_locale
            position
            image {
              altText
              mobileImage {
                gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
              }
              link {
                title
                link {
                  link
                }
              }
            }
          }
        }
      }
    }
  `);

  const { menuWrapper: allContentfulMenuWrapper, allContentfulMobileMenuCard } = categoryMenu;

  const menuCards = useMemo(() => {
    let cards = null;
    if (allContentfulMobileMenuCard.edges && allContentfulMobileMenuCard.edges.length > 0) {
      const allMenuCardsArray = edgesToArray(allContentfulMobileMenuCard);
      const defaultCards = allMenuCardsArray.filter((card) => card.node_locale === "en-US");
      const localeSpecificCards = allMenuCardsArray.filter(
        (card) => card.node_locale === gePriceDetails.Culture
      );
      cards = localeSpecificCards ?? defaultCards;
    }
    return cards;
  }, [allContentfulMobileMenuCard, gePriceDetails.Culture]);

  const onMenuLinkClick = (category, subCategory) => {
    const eventLabel = subCategory ? `${category} -> ${subCategory}` : `${category}`;
    gaCustomEvent("Menu Link", "Click", eventLabel);
    closeMenu();
  };

  // Use this to keep track of which submenu is expanded for a11y reasons
  const initialCategoryMenuStatus = {};

  let menus = null;
  if (allContentfulMenuWrapper?.edges && allContentfulMenuWrapper?.edges.length > 0) {
    const allMenuArray = edgesToArray(allContentfulMenuWrapper);
    const defaultMenu = allMenuArray.find((n) => n?.countries?.some((c) => c.code === "RoW"));
    const localeSpecificMenu = allMenuArray.filter((n) =>
      n?.countries?.some((c) => c.code === gePriceDetails.CountryCode)
    );
    const localeSpecificMenuRoot = localeSpecificMenu.find(
      (b) => b.node_locale === gePriceDetails.Culture
    );
    menus = localeSpecificMenuRoot?.mobileMenuItems ?? defaultMenu.mobileMenuItems;
  }

  const categoryMenus = menus.map((menu, index) => {
    const card = menuCards.find((item) => item.position === index + 1);
    const menuCategory = menu.menuContainer[0].menuCategory[0];
    // If the category doesn't have a sub-menu, just return a link
    if (!menuCategory.mobileMenuItems) {
      return (
        <div key={`category-menu-${menuCategory.id}`}>
          <MobileCard card={card} closeMenu={closeMenu} />
          <Link
            className={`mobile_menu_container_link ${
              menu.useImageAsMenu ? "mobile_menu_container_image" : ""
            }`}
            to={menuCategory.titleLink}
            onClick={() => onMenuLinkClick(menuCategory.title)}
            style={{
              color: menu.textColor,
              backgroundColor: !menu.useImageAsMenu ? menu.backgroundColor : "unset",
              backgroundImage: menu.image ? `url(${menu.image.mobileImage.file.url})` : "none",
            }}
          >
            {!menu.useImageAsMenu && menuCategory.title}
          </Link>
        </div>
      );
    }

    initialCategoryMenuStatus[menuCategory.title] = false;

    // The items that will be contained in the sub-menu
    const menuCategoryItems = (
      <div>
        {menuCategory.mobileMenuItems?.map((item) => (
          <Link
            className="mobile_submenu_container_link"
            to={item.link.link.link}
            key={item.link.link.id}
            onClick={() => onMenuLinkClick(menuCategory.title, item.link.title)}
          >
            {item.link.title}
            {item.image && (
              <GatsbyImage
                image={getImage(item.image.mobileImage.gatsbyImageData)}
                alt={item.image.altText}
              />
            )}
          </Link>
        ))}
        {menu.productsCarousel && (
          <BrowseProducts
            content={menu.productsCarousel}
            handleProductClick={closeMenu}
            componentType={ComponentType.MENU}
          />
        )}
      </div>
    );

    return (
      <div key={`category-menu-${menuCategory.id}`} className="mobile_menu_link_container">
        <MobileCard card={card} closeMenu={closeMenu} />
        <MobileMenuButton
          handleClick={() => activateCategoryMenu(menuCategoryItems, menuCategory.title)}
          status={categoryMenuStatus}
          title={menuCategory.title}
          keyId={menuCategory.id}
          color={menu.textColor}
          backgroundColor={menu.backgroundColor}
          image={menu.image}
          useImageAsMenu={menu.useImageAsMenu}
        />
      </div>
    );
  });

  useEffect(() => {
    initializeMenuStatuses(categoryMenuStatus, subMenuStatus);
  }, []);

  const cards = menuCards.filter((item) => item.position >= menus.length);
  return (
    <div className="mobile_menu_container">
      {categoryMenus}
      {cards.map((card, i) => (
        <MobileCard key={`mobile_menu_card_${i}`} card={card} closeMenu={closeMenu} />
      ))}
    </div>
  );
}

CategoryMenu.propTypes = {
  activateCategoryMenu: PropTypes.func,
  categoryMenuStatus: PropTypes.object,
  subMenuStatus: PropTypes.object,
  closeMenu: PropTypes.func.isRequired,
};

export default withALErrorBoundary({
  name: "CategoryMenu",
  priority: "P2",
})(CategoryMenu);
