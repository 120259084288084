import { PropTypes } from "prop-types";

import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";

import * as Styles from "./categories/styles.module.scss";

function Categories({ categories, refine }) {
  const dict = useLocalizedSentenceDict();

  return (
    <div className={Styles.categories}>
      {categories.map((keyword) => (
        <button
          type="button"
          key={keyword}
          className={`text ${Styles.categories_item}`}
          onClick={() => refine(keyword)}
        >
          {dict.get(keyword)}
        </button>
      ))}
    </div>
  );
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  refine: PropTypes.func,
};

export default Categories;
