import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../../../helpers/ErrorBoundary/ALErrorBoundary";
import { HiddenElementsContext } from "../../../../context/HiddenElements";
import { useTracking } from "../../../../context/Tracking";
import { ALButton } from "../../../ALComponents";
import ProductElement from "./optionalProductsDrawer/ProductElement";

import IconCloseRound from "../../../icons/svgs/close-round.svg";

import * as Styles from "./optionalProductsDrawer/styles.module.scss";

function OptionalProductsDrawer({
  isVisible,
  originProductTitle, // the title of the product that the user is currently viewing (for tracking purposes)
  optionalProductsTitle, // the contentful title for the optional product collection, i.e. "Style with charms"
  initialProducts,
  allProducts = [],
  onSave,
  onClose,
}) {
  const { show, hide } = useContext(HiddenElementsContext);
  const { trackOptionalProductsDrawerAdd, trackOptionalProductsDrawerRemove } = useTracking();

  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (initialProducts) {
      setSelectedProducts(initialProducts);
    }
  }, [initialProducts]);

  useEffect(() => {
    if (isVisible) {
      hide();
    } else {
      show();
    }
  }, [isVisible, show, hide]);

  const onToggleSelectHandle = (handle, prices) => {
    if (selectedProducts.map((product) => product.handle).includes(handle)) {
      setSelectedProducts(selectedProducts.filter((p) => p.handle !== handle));
      trackOptionalProductsDrawerRemove({
        originProductTitle,
        handle,
        price: prices.finalPrice,
        currency: prices.currencyCode,
      });
    } else {
      const productToAdd = allProducts.find((p) => p.node.handle === handle)?.shopify;
      if (productToAdd) {
        setSelectedProducts([...selectedProducts, { ...productToAdd, prices }]);
        trackOptionalProductsDrawerAdd({
          originProductTitle,
          handle,
          price: prices.finalPrice,
          currency: prices.currencyCode,
        });
      } else {
        console.warn("Optional product select failed: product not found");
      }
    }
  };

  let confirmationText = "Confirm selection";
  if (selectedProducts.length > 1) {
    confirmationText = `Confirm ${selectedProducts.length} selections`;
  } else if (selectedProducts.length === 1) {
    confirmationText = "Confirm 1 selection";
  }

  return (
    <div className={Styles.container}>
      <div
        onClick={onClose}
        className={`${Styles.backdrop} ${
          isVisible ? Styles.backdropVisible : Styles.backdropClosed
        }`}
      />

      <div className={`${Styles.drawer} ${isVisible ? Styles.drawerOpen : Styles.drawerClosed}`}>
        <div className={Styles.header}>
          <h4 className="h4 h4--bold h4--uc m-0">{optionalProductsTitle}</h4>
          <IconCloseRound fill="none" stroke="#2D2927" width="30px" onClick={onClose} alt="close" />
        </div>

        <div className={Styles.products}>
          {allProducts.map((product) => (
            <ProductElement
              key={product.node.handle}
              product={product}
              isSelected={selectedProducts
                .map((product) => product.handle)
                .includes(product.node.handle)}
              onToggle={onToggleSelectHandle}
            />
          ))}
        </div>

        <div className={Styles.bottomSection}>
          <ALButton
            variant="primary"
            fullWidth
            onClick={() => {
              onSave(selectedProducts);
            }}
          >
            {confirmationText}
          </ALButton>
        </div>
      </div>
    </div>
  );
}

OptionalProductsDrawer.propTypes = {
  isVisible: PropTypes.bool,
  originProductTitle: PropTypes.string,
  optionalProductsTitle: PropTypes.string,
  initialProducts: PropTypes.array,
  allProducts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        handle: PropTypes.string,
      }),
    })
  ),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default withALErrorBoundary({
  name: "OptionalProductsDrawer",
  priority: "P3",
})(OptionalProductsDrawer);
