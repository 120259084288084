import useGetProductsByCollection from "./useGetProductsByCollection";
import { useProductData2 } from "../context/products";
import { useCurrentCountryCode } from "./usePrices";

function useCollectionProducts(collectionHandle, limit = 50) {
  const countryCode = useCurrentCountryCode();
  const productHandles = useGetProductsByCollection(collectionHandle, limit);

  const { data: productsData } = useProductData2(productHandles, "long", countryCode);

  return productsData;
}

export default useCollectionProducts;
