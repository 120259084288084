import { isBrowser } from "../helpers";

export function expSendViewProductPage({ price, title, productType, productId }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("view_item", {
      product_id: productId,
      price: parseFloat(price), // FLOAT
      title,
      brand: "Ana Luisa",
      category_1: productType,
    });
  }
}

export function expSendAddToWishlist({ productHandle, price, productId, email }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("add_item_wishlist", {
        product_id: productId,
        title: productHandle,
        price,
        email,
      });
    }
  }
}

export function expSendRemoveFromWishlist({ productHandle, price, productId, email }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("remove_item_wishlist", {
        product_id: productId,
        title: productHandle,
        price,
        email,
      });
    }
  }
}

export function expTrackRecommendedProduct(action, data) {
  window.exponea.track("recommended_product", { action, ...data });
}

export function expTrackBundleUpdate({ eventName, handle, price, currency, productId, step }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track(eventName, {
        product_id: productId,
        title: handle,
        price,
        currency,
        step,
      });
    }
  }
}

export function expTrackAddBundleToCart({ fullPrice, finalPrice, currency, products }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("add_bundle_cart", {
        fullPrice,
        finalPrice,
        products,
        currency,
      });
    }
  }
}

export function expTrackBundleItems({
  numberItems,
  rating,
  fullPrice,
  finalPrice,
  currency,
  products,
}) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("bundle_items", {
        numberItems,
        rating,
        fullPrice,
        finalPrice,
        products,
        currency,
      });
    }
  }
}

export function expSendUserSignup({ email, httpStatus, data }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("user_signup", { email, httpStatus, data });
    }
  }
}

export function expTrackClickAlternative({
  page,
  activeProductHandle,
  activeProductColor,
  alternativeHandle,
  alternativeColor,
}) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("click_alternative", {
        page,
        activeProductHandle,
        activeProductColor,
        alternativeHandle,
        alternativeColor,
      });
    }
  }
}

export function expSendUserLogin({ email }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("user_login", { email });
    }
  }
}

/**
 * action: SIGNUP, LOGIN, FORGOT_PASSWORD, LOGOUT, ACTIVATE_ACCOUNT
 */
export function expSendAccountError({ action, errorMessage, email }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("account_error", { action, errorMessage, email });
    }
  }
}

export function expSendDropHintClosePopup({ pageUrl }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("drop_hint_close_popup", { pageUrl });
    }
  }
}

export function expSendDropHintOpenPopup({ pageUrl }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("drop_hint_open_popup", { pageUrl });
    }
  }
}

export function expSendDropHintIconClick({ pageUrl, socialIcon }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("drop_hint_icon_click", { pageUrl, socialIcon });
    }
  }
}

export function expSendBackToTopClick({ pageUrl }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("back_to_top_click", { pageUrl });
    }
  }
}

export function expSendSideCartOpen({ cart, currency }) {
  if (isBrowser) {
    if (window.exponea) {
      const data = {
        ...cart,
        currency,
      };
      window.exponea.track("view_sidecart", data);
    }
  }
}

export function expSendMenuClick({
  destinationUrl,
  menuTitle,
  categoryTitle,
  subCategoryTitle,
  sourceType,
}) {
  if (isBrowser && window.exponea) {
    window.exponea.track("main_menu_click", {
      sourceType,
      destinationUrl,
      menuTitle,
      categoryTitle,
      subCategoryTitle,
    });
  }
}

export function expSendCollectionFilterClick({ category, title, turningOn }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("collection_filter_click", {
      category,
      title,
      action: turningOn ? "turn_on" : "turn_off",
    });
  }
}

export function expSendCollectionSortClick({ value }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("collection_sort_click", {
      sort_value: value,
    });
  }
}

export function expSendFloatingAddToCart({
  price,
  title,
  variantId,
  productId,
  sku,
  category,
  currency,
}) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("floating_add_to_cart", {
        price,
        title,
        variantId,
        productId,
        sku,
        category,
        currency,
      });
    }
  }
}

export function expTrackAddPreBundledProductToCart({
  bundleName,
  cpPosition,
  fullPrice,
  finalPrice,
  products,
}) {
  if (isBrowser && window.exponea) {
    window.exponea.track("add_prebundled_product_to_cart", {
      bundleName,
      cpPosition,
      fullPrice,
      finalPrice,
      products,
    });
  }
}

export function expTrackQuickViewClick({ collectionHandle, cpPosition, countryCode, product }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("quick_view_click", {
      productHandle: product?.handle || "",
      productTitle: product?.title || "",
      productPrice: product?.prices?.price || "",
      collectionHandle,
      cpPosition,
      countryCode,
    });
  }
}

export function expTrackViewProductDetailsOnByob({ handle, id, title, price, category }) {
  if (isBrowser) {
    if (window.exponea) {
      window.exponea.track("view_product_byob", {
        handle,
        id,
        title,
        price,
        category,
      });
    }
  }
}

export function expTrackCollectionCards({ collectionHandle, link, title }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("plp_cards", {
      collectionHandle,
      link,
      title,
      action: "click",
    });
  }
}

export function expTrackSubscriptionCancel(reason) {
  if (isBrowser && window.exponea) {
    window.exponea.track("subscription_change", {
      type: "CANCEL",
      time: 0,
      reason,
    });
  }
}

export function expTrackSubscriptionSkip() {
  if (isBrowser && window.exponea) {
    window.exponea.track("subscription_change", {
      type: "PAUSE",
      time: 1,
    });
  }
}

export function expTrackSubscriptionPause(interval) {
  if (isBrowser && window.exponea) {
    window.exponea.track("subscription_change", {
      type: "PAUSE",
      time: interval,
    });
  }
}

export function expTrackSubscriptionReactivate() {
  if (isBrowser && window.exponea) {
    window.exponea.track("subscription_change", {
      type: "REACTIVATE",
      time: 0,
    });
  }
}

export function expTrackAddToCart({
  product_ids,
  product_list,
  sku_ids,
  sku_list,
  variant_ids,
  variant_list,
  total_price,
  total_price_local_currency,
  total_quantity,
}) {
  if (isBrowser && window.exponea) {
    const d = {
      action: "add",
      product_ids,
      product_list,
      sku_ids,
      sku_list,
      variant_ids,
      variant_list,
      total_price,
      total_price_local_currency,
      total_quantity,
    };
    window.exponea.track("cart_update", d);
  }
}

export function expOptionalProductsDrawerOpen({ originProductTitle }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("optional_products_drawer_open", {
      from: originProductTitle,
    });
  }
}

export function expOptionalProductsDrawerAdd({ originProductTitle, handle, price, currency }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("optional_products_drawer_add", {
      from: originProductTitle,
      handle,
      price,
      currency,
    });
  }
}

export function expOptionalProductsDrawerRemove({ originProductTitle, handle, price, currency }) {
  if (isBrowser && window.exponea) {
    window.exponea.track("optional_products_drawer_remove", {
      from: originProductTitle,
      handle,
      price,
      currency,
    });
  }
}

export function expOptionalProductsDrawerEdit({
  originProductTitle,
  addedHandles,
  price,
  currency,
}) {
  if (isBrowser && window.exponea) {
    window.exponea.track("optional_products_drawer_edit", {
      from: originProductTitle,
      handles: addedHandles,
      price,
      currency,
      quantity: addedHandles.length,
    });
  }
}

export function expOptionalProductsDrawerConfirmation({
  originProductTitle,
  handles,
  price,
  currency,
}) {
  if (isBrowser && window.exponea) {
    window.exponea.track("optional_products_drawer_confirm", {
      from: originProductTitle,
      handles,
      price,
      currency,
      quantity: handles.length,
    });
  }
}
