import { useContext, useState } from "react";
import PropTypes from "prop-types";

import { DiscountContext } from "../context/DiscountProvider";
import { applyIgPrices } from "../helpers/intelligems";
import { LuxeContext } from "../context/LuxeProvider";
import { GeoContext } from "../context/GeoProvider";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import { useExclusionTags } from "../hooks/useExclusionTags";
import useDeviceDetect from "../hooks/useDeviceDetect";
import { getCookie } from "../context/helpers";
import { formatPriceAmount } from "../hooks/usePrices";

import { ALSkeleton } from "./ALComponents";
import { ComponentType } from "../constants/ComponentType";
import DISCOUNT_TYPES from "../constants/DiscountTypes";
import SubscriptionPopUp from "./SubscriptionPopUp";

import LuxeIcon from "./icons/svgs/luxe.svg";
import InfoIcon from "./icons/svgs/info.svg";

import * as Styles from "./priceContainer/styles.module.scss";

const LUXE_EXCLUDED_COMPONENTS = [
  ComponentType.PDP_ADS,
  ComponentType.CP_ADS,
  ComponentType.SIDE_CART_UPSELL,
  ComponentType.FLOATING_ATC_ADS,
];

function PriceContainer({
  productId,
  selectedVariant,
  prices,
  pricesLoading,
  componentType,
  tags,
  optionalProductsTotalPrice = 0,
}) {
  const { discountInfo } = useContext(DiscountContext);
  const { luxe, applyLuxeDiscountToProductPrice } = useContext(LuxeContext);
  const { gePriceDetails } = useContext(GeoContext);

  const { isMobile } = useDeviceDetect();
  const exclusionTags = useExclusionTags();
  const dict = useLocalizedSentenceDict();

  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  const disableSubscriptionCookie = getCookie("subscription_disabled");
  const shouldDisableLuxe = !!disableSubscriptionCookie;

  const showLuxePrices =
    luxe.isLuxeEnabled &&
    !shouldDisableLuxe &&
    !(tags || []).some((r) => discountInfo.luxeExclusionTags?.includes(r)) &&
    !LUXE_EXCLUDED_COMPONENTS.includes(componentType);

  const luxeIcon =
    componentType === ComponentType.PDP || componentType === ComponentType.QUICKVIEW ? (
      <LuxeIcon width={isMobile ? "20" : "24"} className={Styles.price_container__luxeIcon} />
    ) : (
      <LuxeIcon height={isMobile ? "14" : "16"} className={Styles.price_container__luxeIcon} />
    );

  const { compareAtPrice, hasDiscount, finalPrice, fullPrice } = applyIgPrices(
    productId,
    selectedVariant,
    prices
  );

  const luxeFinalPrice = applyLuxeDiscountToProductPrice(
    parseFloat(finalPrice) + optionalProductsTotalPrice
  );

  let discountText = null;
  if (
    discountInfo?.type === DISCOUNT_TYPES.BOGO &&
    componentType !== ComponentType.BYOB &&
    componentType !== ComponentType.BYOB_PDP &&
    componentType !== ComponentType.QUICKVIEW &&
    !hasDiscount &&
    !(tags || []).some((r) => exclusionTags.includes(r))
  ) {
    discountText = dict.get(`BOGO {0}% OFF`, discountInfo?.value);
  }

  let wrapperClass = Styles.prices_cp;
  if (componentType === ComponentType.PDP || componentType === ComponentType.BYOB_PDP) {
    wrapperClass = Styles.prices_pdp;
  }

  if (componentType === ComponentType.PDP_ADS) {
    wrapperClass = Styles.prices_pdp_ads;
  }

  const fullPriceClass = [];
  if (hasDiscount) {
    fullPriceClass.push(Styles.price_container_before, "strikethrough", "dark-grey");
  }
  if (!compareAtPrice) {
    fullPriceClass.push(Styles.price_container_full_has_discount_right);
  }
  if (
    componentType === ComponentType.CP ||
    componentType === ComponentType.CP_ADS ||
    componentType === ComponentType.CP_OPTIONAL_PRODUCTS ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS ||
    componentType === ComponentType.WISHLIST
  ) {
    fullPriceClass.push(Styles.price_container_full_cp);
  }

  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    fullPriceClass.push(Styles.price_container_full_floating, "subtext", "subtext--bold");
  }

  if (componentType === ComponentType.PRODUCT_CAROUSEL) {
    fullPriceClass.push("subtext", "subtext--bold");
  }

  if (componentType === ComponentType.SIDE_CART_UPSELL) {
    fullPriceClass.push("text", "text--bold");
  }

  if (pricesLoading) {
    return (
      <div
        className={`${
          componentType === ComponentType.CP || componentType === ComponentType.CP_ADS
            ? Styles.skeletonWrapper
            : ""
        }`}
      >
        <ALSkeleton width="31px" height="18px" />
      </div>
    );
  }

  if (!prices || prices?.price === undefined) {
    return null;
  }

  const rootClassNames = ["price_container", Styles.price_container];
  if (showLuxePrices) {
    rootClassNames.push(Styles.price_container_luxe);
  }
  if (
    componentType === ComponentType.PDP ||
    componentType === ComponentType.PDP_ADS ||
    componentType === ComponentType.BYOB_PDP ||
    componentType === ComponentType.QUICKVIEW
  ) {
    rootClassNames.push(Styles.price_container_pdp);
  }
  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    rootClassNames.push(Styles.price_container_floating);
  }
  if (componentType === ComponentType.PRODUCT_CAROUSEL) {
    rootClassNames.push(Styles.price_container__product_carousel);
  }

  const pricePercentageClassNames = [];
  pricePercentageClassNames.push(Styles.price_container_percentage);
  if (
    componentType === ComponentType.CP ||
    componentType === ComponentType.CP_ADS ||
    componentType === ComponentType.CP_OPTIONAL_PRODUCTS ||
    componentType === ComponentType.SEARCH ||
    componentType === ComponentType.BYOB ||
    componentType === ComponentType.WISHLIST
  ) {
    rootClassNames.push(Styles.price_container__cp);
    pricePercentageClassNames.push(Styles.price_container_percentage__productCollection);
  }

  if (
    componentType === ComponentType.FLOATING_ATC ||
    componentType === ComponentType.FLOATING_ATC_ADS
  ) {
    pricePercentageClassNames.push(Styles.floating__price_container_percentage);
  }

  return (
    <div className={rootClassNames.join(" ")}>
      {showLuxePrices && (
        <div className={`${Styles.price_container__luxe} ${Styles[componentType.toLowerCase()]}`}>
          {luxeIcon}
          <p className={Styles.price_container__luxePrice} data-testid="luxe-price">
            {luxeFinalPrice}
          </p>
          {componentType === ComponentType.PDP && (
            <button type="button" onClick={() => setInfoPopupOpen(!infoPopupOpen)}>
              <InfoIcon
                width={isMobile ? "15" : "20"}
                className={Styles.price_container__luxeIconInfo}
              />
            </button>
          )}
          <SubscriptionPopUp isOpen={infoPopupOpen} onClose={() => setInfoPopupOpen(false)} />
        </div>
      )}
      <div>
        <div className={wrapperClass}>
          {fullPrice && (
            <p className={fullPriceClass.join(" ")} data-testid="fullPrice">
              {formatPriceAmount(
                parseFloat(fullPrice) + optionalProductsTotalPrice,
                gePriceDetails?.CurrencyCode,
                gePriceDetails?.CountryCode
              )}
            </p>
          )}

          {hasDiscount && finalPrice && (
            <p
              className={`subtext ${Styles.price_container_final} ${
                !luxe.isLuxeEnabled ? "poppy subtext--bold" : ""
              }`}
              data-testid="finalPrice"
            >
              {formatPriceAmount(
                parseFloat(finalPrice) + optionalProductsTotalPrice,
                gePriceDetails?.CurrencyCode,
                gePriceDetails?.CountryCode
              )}
            </p>
          )}
        </div>

        {discountText && (
          <div
            className={pricePercentageClassNames.join(" ")}
            data-discount={prices.discount_given}
            data-testid="discountText"
          >
            {discountText}
          </div>
        )}
      </div>
    </div>
  );
}

PriceContainer.propTypes = {
  componentType: PropTypes.string,
  optionalProductsTotalPrice: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  prices: PropTypes.object,
  pricesLoading: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "PriceContainer",
  priority: "P1",
})(PriceContainer);
