import { useState, useEffect } from "react";
import axios from "axios";

import { useCurrentCountryCode } from "./usePrices";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

function useGetProductsByCollection(collectionHandle, limit = 16) {
  const [handles, setHandles] = useState(null);
  const countryCode = useCurrentCountryCode();
  const { sendReport } = useALError();

  useEffect(() => {
    if (handles === null && collectionHandle) {
      axios
        .get(
          `/.netlify/functions/get-products-by-collection?handle=${collectionHandle}&countryCode=${countryCode}`
        )
        .then((r) => {
          if (r.status == 200) {
            if (r.data && r.data.handles && r.data.handles.length > 0) {
              setHandles(r.data.handles.splice(0, limit));
            } else {
              setHandles([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          sendReport(err, { name: "useGetProductsByCollection", priority: "P5" });
        });
    }
  }, [collectionHandle, countryCode, limit, handles, sendReport]);

  return handles;
}

export default useGetProductsByCollection;
