import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import { GeoContext } from "../../context/GeoProvider";
import { convertPrice } from "../../context/helpers";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import { useALError, withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

const PriceString = ({ text }) => {
  const { gePriceDetails } = useContext(GeoContext);
  const [newText, updateText] = useState(false);
  const formatCurrency = useCurrentFormatCurrency();
  const { throwReport } = useALError();

  useEffect(() => {
    if (gePriceDetails && gePriceDetails?.CountryCode !== "US") {
      try {
        const reg = new RegExp(/\$((?:\d|\,)*\.?\d+)/g);
        const matches = text.match(reg);
        if (matches !== null && matches.length > 0) {
          matches.forEach((e) => {
            const value = parseFloat(e.replace("$", ""));
            const convertedValue = convertPrice(value, gePriceDetails);
            updateText(text.replace(e, formatCurrency(convertedValue)));
          });
        }
      } catch (err) {
        console.log(err);
        throwReport(err);
      }
    }
  }, [gePriceDetails, text, throwReport, formatCurrency]);

  return newText || text;
};

PriceString.propTypes = {
  text: PropTypes.string.isRequired,
};

export default withALErrorBoundary({
  name: "PriceString",
  priority: "P1",
})(PriceString);
