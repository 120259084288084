import PropTypes from "prop-types";
import { ComponentType } from "../../../constants/ComponentType";
import { ALLink } from "../../ALComponents";
import { isBrowser } from "../../../context/helpers";
import * as productAlternativeStyles from "../styles.module.scss";

function ProductAlternativeTextBlockProduct({
  handle,
  isActive,
  availableForSale,
  labelName,
  fullWidth,
  componentType,
  children,
}) {
  const blockClasses = [productAlternativeStyles.blockContainer];

  if (isActive) {
    blockClasses.push(productAlternativeStyles.blockContainerActive);
  }
  if (!availableForSale) {
    blockClasses.push(productAlternativeStyles.blockContainerUnavailable);
  }
  if (fullWidth) {
    blockClasses.push(productAlternativeStyles.blockContainerFullWidth);
  }

  return (
    <span key={`alternative-${handle}-${labelName}`} className={blockClasses.join(" ")}>
      <ALLink
        to={
          componentType === ComponentType.PDP_ADS
            ? `/p/${handle}/${isBrowser ? window.location.search : ""}`
            : `/products/${handle}/`
        }
        className={productAlternativeStyles.bubble}
        title={labelName || ""}
      >
        {children}
      </ALLink>
    </span>
  );
}

ProductAlternativeTextBlockProduct.propTypes = {
  handle: PropTypes.string,
  isActive: PropTypes.bool,
  availableForSale: PropTypes.bool,
  labelName: PropTypes.string,
  fullWidth: PropTypes.bool,
  componentType: PropTypes.string,
  children: PropTypes.node,
};

export default ProductAlternativeTextBlockProduct;
