import DISCOUNT_TYPES from "../constants/DiscountTypes";
import ROBOTS_USER_AGENTS from "../constants/RobotList";

const crypto = require("crypto");
const axios = require("axios");

const { Country } = require("../constants/Country");
const { mapDeliveryTimeContent } = require("./deliveryTime");

const { CalculatePrice } = require("./helpers_ge");
const shippingFees = require("./shippingFees.json");

const AL_SHA_ACC = process.env.GATSBY_AL_SHA_ACC; // Must be 256 bits (32 characters)
const IV_LENGTH = 16;
const ENGRAVABLE_CHAR_PATTERN = /[0-9A-Z~!@#\$%^&*\(\)\-\+=\{\[\}\]:;"'<,>\.\?\/`\s]/;

export function formatEngravableText(text, maxLength = 8) {
  let s = "";
  let i = 0;
  text = text?.toUpperCase();
  while (s.length < maxLength && i < text?.length) {
    if (ENGRAVABLE_CHAR_PATTERN.test(text[i])) {
      s += text[i];
    }
    i++;
  }
  return s;
}

export function getFreeShippingThresholdFromCountry(country) {
  try {
    const _shipping = shippingFees[country.CountryName];
    if (_shipping.currency == country.CurrencyCode) {
      return _shipping.freeShippingCartValue;
    }
    // Convert the fees from USD to the correct currency
    const convertedValue = convertPrice(_shipping.freeShippingCartValue, country);
    return convertedValue;
  } catch (error) {
    return null;
  }
}

export function getShippingFeeFromCountry(country) {
  try {
    const _shipping = shippingFees[country.CountryName];

    if (_shipping.currency == country.CurrencyCode) {
      return _shipping.shippingFree;
    }
    // Convert the fees from USD to the correct currency
    const price = convertPrice(_shipping.shippingFree, country);
    return price;
  } catch (error) {
    return null;
  }
}

export function encryptData(text) {
  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(AL_SHA_ACC), iv);
  let encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return `${iv.toString("hex")}:${encrypted.toString("hex")}`;
}

export function decryptData(text) {
  const textParts = text.split(":");
  const iv = Buffer.from(textParts.shift(), "hex");
  const encryptedText = Buffer.from(textParts.join(":"), "hex");
  const decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(AL_SHA_ACC), iv);
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  return decrypted.toString();
}

export function getItemCipheredAttributeNumberValue(item, key, defaultValue) {
  let result = defaultValue;
  const attrValue = item.attributes.find(({ key: attrKey }) => attrKey === key)?.value;
  if (attrValue) {
    result = Number(simpleDecipher(attrValue)) || defaultValue;
  }
  return result;
}

// Source: https://github.com/vyushin/ticks-to-date
function ticksToDate(ticks) {
  return Number.isInteger(ticks)
    ? new Date(ticks / 1e4 + new Date("0001-01-01T00:00:00Z").getTime())
    : null;
}

/**
 * Read the JavaScript cookies tutorial at:
 *   http://www.netspade.com/articles/javascript/cookies.xml
 */
export const isBrowser = typeof window !== "undefined";

export const hasLoyaltyLion = () => isBrowser && typeof window?.loyaltylion !== "undefined";

/**
 * Sets a Cookie with the given name and value.
 *
 * name       Name of the cookie
 * value      Value of the cookie
 * [days]     Number of days before expiration
 */
export function createCookie(name, value, days) {
  if (isBrowser) {
    let expires = "";
    if (!Number.isNaN(days)) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
  }
  return value;
}

export function isCrawlerBot() {
  if (isBrowser) {
    const { userAgent } = window.navigator;
    if (userAgent) {
      const lowerCaseUserAgent = userAgent.toLowerCase();
      return !!ROBOTS_USER_AGENTS.find((token) => lowerCaseUserAgent.includes(token.toLowerCase()));
    }
  }
  return false;
}

/**
 * Sets a Cookie with the given name and value.
 *
 * name       Name of the cookie
 * value      Value of the cookie
 * [days]     Number of days before expiration
 * [ticks]    Expiration time in c# ticks
 */
export function createRootDomainCookie(name, value, days, ticks) {
  if (isBrowser) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toGMTString()}`;
    }
    if (ticks) {
      const dateTicks = ticksToDate(ticks);
      if (dateTicks) {
        expires = `; expires=${dateTicks.toGMTString()}`;
      }
    }
    window.location.hostname.split(".").slice(-2).join(".");
    document.cookie = `${name}=${value}${expires};${
      window.location.hostname === "localhost" ? "" : "domain=.analuisa.com;"
    }path=/`;
  }
  return value;
}

/**
 * Gets the value of the specified cookie.
 *
 * name  Name of the desired cookie.
 *
 * Returns a string containing value of specified cookie,
 *   or null if cookie does not exist.
 */
export function getCookie(name) {
  if (isBrowser) {
    const dc = document.cookie;
    const prefix = `${name}=`;
    let begin = dc.indexOf(`; ${prefix}`);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    } else {
      begin += 2;
    }
    let end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }
    const c = unescape(dc.substring(begin + prefix.length, end));
    if (c === "[object Object]") return "";
    return c;
  }
}

/**
 * Delete the specified cookie.
 *
 * @name {string} - Name of the cookie to delete.
 *
 */
export function deleteCookie(name) {
  createCookie(name, "", 0);
}

/**
 * Get all params from a URL
 *
 * Returns an object with values:
 *   - as string (if the key has one value)
 *   - as array (if a key has more values)
 *
 */
export function getAllUrlParams(url) {
  if (isBrowser) {
    const result = {};
    const urlCopy = url || window.location.href;
    const newUrl = new URL(urlCopy);
    const params = newUrl.searchParams;
    params.forEach((value, key) => {
      if (result[key]) {
        if (Array.isArray(result[key])) {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    });

    return result;
  }
  return {};
}

export function addURLParam(urlParam, paramValue, url = null) {
  if (isBrowser) {
    const urlToChange = new URL(url || window.location.href);
    const params = new URLSearchParams(urlToChange.search);
    params.append(urlParam, paramValue);
    window.history.replaceState(
      null,
      "",
      `${urlToChange.origin}${urlToChange.pathname}?${params.toString()}`
    );
  }
}

export function removeURLParam(urlParam, paramValue = null, applyOnAll = false, url = null) {
  const urlToChange = new URL(url || window.location.href);
  let newParamsEntries = Array.from(new URLSearchParams(urlToChange.search).entries());
  if (applyOnAll) {
    newParamsEntries = newParamsEntries.filter(
      ([key, value]) => key !== urlParam || (paramValue !== null && paramValue !== value)
    );
  } else {
    const paramIndexToRemove = newParamsEntries.findIndex(
      ([key, value]) => key === urlParam && (paramValue === null || paramValue === value)
    );
    if (paramIndexToRemove > -1) {
      newParamsEntries.splice(paramIndexToRemove, 1);
    }
  }
  window.history.replaceState(
    null,
    "",
    `${urlToChange.origin}${urlToChange.pathname}${
      newParamsEntries.length ? `?${new URLSearchParams(newParamsEntries).toString()}` : ""
    }`
  );
}

/**
 * Update an URL query parameter
 * @param {string} urlParam - The key name of the parameter to update. If the key to update does not exist a new parameter is created.
 * @param {string} newValue - The new value of the parameter to update.
 * @param {string} previousValue - Previous value of the parameter. To specify if there are multiple parameters with the same key (array).
 * @param {boolean} applyOnAll - If `true` the update will apply on all matching parameters. Default `false`: the update applies only on the first found.
 * @param {string} url - URL to apply the update
 */
export function updateURLParam(
  urlParam,
  newValue,
  previousValue = null,
  applyOnAll = false,
  url = null
) {
  if (newValue === null) return removeURLParam(urlParam, previousValue, applyOnAll, url);
  const urlToChange = new URL(url || window.location.href);
  let paramsEntries = Array.from(new URLSearchParams(urlToChange.search).entries()); // [[key 1, value 1], [key 2, value 2]]
  const paramIndexToUpdate = paramsEntries.findIndex(
    ([key, value]) => key === urlParam && (previousValue === null || previousValue === value)
  );
  if (paramIndexToUpdate === -1) return addURLParam(urlParam, newValue, url); // Add the param if not found
  if (applyOnAll) {
    paramsEntries = paramsEntries.map(([key, value]) => {
      if (key === urlParam && (previousValue === null || previousValue === value)) {
        return [key, newValue];
      }
      return [key, value];
    });
  } else {
    paramsEntries[paramIndexToUpdate][1] = newValue;
  }
  window.history.replaceState(
    null,
    "",
    `${urlToChange.origin}${urlToChange.pathname}${
      paramsEntries.length ? `?${new URLSearchParams(paramsEntries).toString()}` : ""
    }`
  );
}

export function edgesToArray(root) {
  return root.edges.map((e) => e.node);
}

export function roundNumber(num) {
  if (Number.isNaN(parseFloat(num))) {
    return "";
  }

  const n = num % 1 == 0 ? 0 : 2;
  return parseFloat(Math.round(num * 10 ** n) / 10 ** n).toFixed(n);
}

export function convertPrice(priceInUSD, geDetails) {
  if (!geDetails || geDetails.CountryCode === "US") return Number(priceInUSD);

  const vatRateTypes = geDetails.VatSettings
    ? {
        IncludeVATTypeId: geDetails.VatSettings.VATTypeId,
        UseCountryVAT: geDetails.VatSettings.UseDistanceSellingVAT,
        LocalVATRateType: {
          Rate: geDetails.VatSettings.LocalVATRate,
        },
        VATRateType: {
          Rate: geDetails.VatSettings.DistanceSellingVATRate,
        },
      }
    : {};
  return CalculatePrice(
    Number(priceInUSD),
    geDetails.CurrencyConversionRate,
    geDetails.CountryCoefficientRate,
    vatRateTypes,
    geDetails.IsGrossPrices,
    geDetails.BaseCurrencyDecimalPlaces,
    geDetails.CountryCoefficientRate,
    geDetails.RoundingRules
  );
}

function parsePrices(prices) {
  const { full_price, final_price, price, ...rest } = prices;
  return {
    full_price: Number.parseFloat(full_price ?? final_price),
    final_price: Number.parseFloat(final_price),
    price: Number(price),
    ...rest,
  };
}

export function getLocalizedPricesWithDiscount({
  compareAtPrice,
  price,
  discount,
  geDetails,
  shouldUseDiscount = true,
}) {
  const discountToUse = shouldUseDiscount ? discount : null;
  const prices = parsePrices(getBasePrices_NEW(compareAtPrice, price, discountToUse));
  return {
    price_before_sitewide_discount: convertPrice(price, geDetails),
    ...prices,
  };
}

/**
 *
 * Use US geDetails for now to prevent currency conversion since localized prices are already fetched from Shopify.
 * Ideally we should remove currency conversion logic from this function.
 */
export function getBasePrices_NEW(compareAtPrice, price, discount) {
  let full_price = null;
  let final_price = null;
  let discount_given = null;

  if (
    discount !== "empty" &&
    discount != null &&
    discount?.type === DISCOUNT_TYPES.SITEWIDE_PERCENT
  ) {
    full_price = compareAtPrice || price;
    final_price = roundNumber(price * ((100 - discount.value) / 100));

    const discount_given = roundNumber(
      roundNumber(((full_price - final_price) / full_price) * 100)
    );

    return {
      full_price,
      final_price,
      discount_given,
      currency: "$",
    };
  }

  final_price = price;
  if (compareAtPrice != null) {
    full_price = compareAtPrice;
    discount_given = roundNumber(roundNumber(((full_price - final_price) / full_price) * 100));
  }
  return {
    full_price,
    final_price,
    discount_given,
    currency: "$",
  };
}

export function checkNestedObj(obj, level, ...rest) {
  if (obj === undefined || obj === null) return false;
  try {
    if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
  } catch (error) {
    return false;
  }
  return checkNestedObj(obj[level], ...rest);
}

export function validateEmail(email) {
  email = email.trim().toLowerCase();
  if (/^\S+@\S+\.\S+$/.test(email)) {
    return email;
  }
  return false;
}

export function generateId(length) {
  if (isBrowser) {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return "";
}

export function roundToTheHalf(inputValue) {
  // if the number is greater than 0.51 round up
  // if the number is less than 0.5 please round down
  const percentile = Math.round(
    (Math.round(inputValue * 10 ** 2) / 10 ** 2 - parseFloat(Math.trunc(inputValue))) * 100
  );
  const outputValue = 0.5 * (percentile >= 49 ? 1 : 0) + 0.5 * (percentile >= 51 ? 1 : 0);
  return Math.trunc(inputValue) + outputValue;
}

export function getDeliveryTimeFromCountryCode(countryCode) {
  if (!countryCode) return 4;
  const buffer_time = 1; // add buffer in case of issue at the warehouse
  const country = mapDeliveryTimeContent.find(
    (e) => e?.country.toLowerCase() === countryCode.toLowerCase()
  );
  if (country) {
    return country.deliveryTime + buffer_time;
  }
  return 5;
}

export function resizeShopifyImage(src, size) {
  if (size === null) {
    return src;
  }
  try {
    const match = src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i);
    if (match) {
      const prefix = src.split(match[0]);
      const suffix = match[0];
      return `${prefix[0]}_${size}${suffix}`;
    }
    return src;
  } catch (error) {
    return src;
  }
}

export function isReviewValid(review) {
  if (review.body && review.created_at && review.name) return true;
  return false;
}

export function gaCustomEvent(eventCategory, eventAction, eventLabel = null, eventValue = null) {
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
  // ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
  // eventCategory	text	yes	Typically the object that was interacted with (e.g. 'Video')
  // eventAction	text	yes	The type of interaction (e.g. 'play')
  // eventLabel	text	no	Useful for categorizing events (e.g. 'Fall Campaign')
  // eventValue	integer	no	A numeric value associated with the event (e.g. 42)
  if (isBrowser && typeof window.ga !== "undefined") {
    if (!eventCategory && !eventAction) {
      console.warn("Missing parameters");
      return;
    }
    const data = {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    };

    window.ga("send", "event", data);
  }
}

export const buildSEOProductObject = (product) => {
  // Build SEO JSON-LD
  const productJsonLd = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.analuisa.com/products/${product.handle}/`,
    name: `${product.title} | Ana Luisa Jewelry`,
    brand: {
      "@type": "Brand",
      name: "Ana Luisa",
    },
    description: product.description,
    category: product.category,
  };

  // Image
  if (product?.images.length > 0) {
    productJsonLd.image = product.images[0].url;
  }

  // SKU
  if (product?.variants.length > 0) {
    productJsonLd.sku = product.variants[0].sku;
  }

  // OFFER
  let availability = product.variants[0].availableForSale
    ? "https://schema.org/InStock"
    : "https://schema.org/OutOfStock";
  if (product.isPreOrder) {
    availability = "https://schema.org/BackOrder";
  }

  // Product offer
  if (product?.prices?.compareAtPrice) {
    productJsonLd.offers = {
      "@type": "AggregateOffer",
      priceCurrency: product.prices?.currencyCode || "USD",
      lowPrice: Number(product.prices.price),
      highPrice: Number(product.prices.compareAtPrice),
      salePrice: Number(product.prices.price),
      offerCount: "2",
    };
  } else {
    productJsonLd.offers = {
      "@type": "Offer",
      priceCurrency: product?.prices?.currencyCode || "USD",
      price: Number(product?.prices?.price),
    };
  }

  const additionalProperties = [];
  Object.keys(product.productDetails || []).forEach((detail) => {
    // Skip adding null values, e.g. charmDimensions: "null"
    if (product.productDetails[detail] === "null") {
      return;
    }

    let value = product.productDetails[detail];
    if (typeof value === "object") {
      value = value.join(", ");
    }

    // Skip adding null values, e.g. post: ["null"];
    if (value === "null") {
      return;
    }

    additionalProperties.push({
      "@type": "PropertyValue",
      name: detail,
      value,
    });
  });

  productJsonLd.offers = {
    ...productJsonLd.offers,
    url: `https://www.analuisa.com/products/${product.handle}`,
    itemCondition: "https://schema.org/NewCondition",
    availability,
    hasMerchantReturnPolicy: "TRUE",
    additionalProperty: additionalProperties,
    shippingDetails: {
      "@type": "OfferShippingDetails",
      deliveryTime: {
        "@type": "ShippingDeliveryTime",
        businessDays: {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [],
        },
        transitTime: {
          "@type": "QuantitativeValue",
          minValue: 2,
          maxValue: 5,
          unitCode: "DAY",
        },
      },
    },
  };

  productJsonLd.audience = {
    "@type": "Audience",
    audienceType: "Women",
  };

  return productJsonLd;
};

export const createTimeCookie = (start, cookieName) => {
  const t = new Date(new Date().getTime() + start * 60000).getTime();
  createCookie(cookieName, t, 3);
  return t;
};

export const hasAddressRequiredFields = (address) => {
  // These fields are required when setting the address for google enhanced conversion
  const requiredFields = ["first_name", "last_name", "postal_code", "country"];
  const addressKeys = Object.keys(address);
  const hasRequiredFields = requiredFields.every((field) => addressKeys.includes(field));

  return hasRequiredFields;
};

export const isVariantOutOfStock = (variant, countryCode = null) =>
  variant?.availableForSale === false ||
  (variant?.inventoryPolicy === "CONTINUE" && Country.US !== countryCode); // Pre-order capabilities disabled outside of US;

export const isOutOfStock = (product, countryCode = null) => {
  const { variants } = product;
  return variants.every((variant) => isVariantOutOfStock(variant, countryCode));
};

export const removeQueryParam = (param) => {
  if (isBrowser) {
    const searchParams = new URLSearchParams(window.location.search);
    const entries = Object.fromEntries(searchParams.entries());
    if (!entries[param]) {
      return;
    }
    searchParams.delete(param);
    /* eslint-disable no-restricted-globals */
    if (history.replaceState) {
      const searchString = searchParams.toString().length > 0 ? `?${searchParams.toString()}` : "";
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${searchString}${window.location.hash}`;
      history.replaceState(null, "", newUrl);
    }
  }
};

export async function getBundlesHandlesArray(countryCode) {
  const bundlesHandle = "jewelry-bundles";
  try {
    const response = await axios.get(
      `/.netlify/functions/get-products-by-collection?handle=${bundlesHandle}&countryCode=${countryCode}`
    );
    return response.data.handles;
  } catch (e) {
    return e;
  }
}

// Legacy, prefer getShopifyObjectId
export function decodeProductId(id) {
  return id?.split("/").pop() || id;
}

// Convert Shopify Object ID
// ex: "gid://shopify/ProductVariant/64982369?cartId=52046502" to "64982369"
export function getShopifyObjectId(id) {
  return id?.split("/")?.pop()?.split("?")?.[0] || id;
}

// Create insecure hash of variable length with decent collision rate
// (as defined by md5 rate and hash length)
export function createHash(text, hashLength = 32, prefix = "") {
  const hash = crypto.createHash("md5");
  hash.update(prefix + text);
  const hashedText = prefix + hash.digest("hex");
  if (hashedText.length < hashLength) {
    return createHash(text, hashLength, hashedText);
  }
  return hashedText.slice(0, hashLength);
}
// Similar to createHash but using baked-in browser crypto library
// export async function createHashLocal(text, hashLength = 32, prefix = "") {
//   const msgBuffer = new TextEncoder().encode(prefix + text);
//   const hashBuffer = await crypto.subtle.digest("SHA-1", msgBuffer);
//   const hashArray = Array.from(new Uint8Array(hashBuffer));
//   const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
//   const hashedText = prefix + hashHex;
//   if (hashedText.length < hashLength) {
//     return createHashLocal(text, hashLength, hashedText);
//   }
//   return hashedText.slice(0, hashLength);
// }

export function generateUniqueID() {
  const time = Date.now().toString(36);
  const random = Number(`1${Math.random().toString().substring(2)}`.slice(1, 15)).toString(36);
  return random + time;
}

export const sanitizePhonePerCountry = (phone) => {
  // SK (length: 8)
  if (phone.length === 10 && phone.startsWith("82")) {
    return `00${phone}`;
  }
  if (phone.length === 11 && phone.startsWith("082")) {
    return `0${phone}`;
  }
  if (phone.length === 12 && phone.startsWith("0082")) {
    return phone;
  }

  // US & CA (length: 10)
  if (phone.length === 11 && phone.startsWith("1")) {
    return `00${phone}`;
  }
  if (phone.length == 12 && phone.startsWith("01")) {
    return `0${phone}`;
  }
  if (phone.length == 13 && phone.startsWith("001")) {
    return phone;
  }

  // FR (length: 9)
  if (phone.length === 10 && phone.startsWith("0")) {
    return `0033${phone.substring(1)}`;
  }
  if (phone.length === 11 && phone.startsWith("33")) {
    return `00${phone}`;
  }
  if (phone.length === 12 && phone.startsWith("033")) {
    return `0${phone}`;
  }
  if (phone.length === 13 && phone.startsWith("0033")) {
    return phone;
  }

  // AU (length: 9)
  if (phone.length === 11 && phone.startsWith("61")) {
    return `00${phone}`;
  }
  if (phone.length === 12 && phone.startsWith("061")) {
    return `0${phone}`;
  }
  if (phone.length === 13 && phone.startsWith("0061")) {
    return phone;
  }

  // UK (length: 10)
  if (phone.length === 11 && phone.startsWith("07")) {
    return `0044${phone.substring(1)}`;
  }
  if (phone.length === 12 && phone.startsWith("44")) {
    return `00${phone}`;
  }
  if (phone.length === 13 && phone.startsWith("044")) {
    return `0${phone}`;
  }
  if (phone.length === 14 && phone.startsWith("0044")) {
    return phone;
  }

  // DE (length: 10, 11)
  if (phone.length === 11 && phone.startsWith("0")) {
    return `0049${phone.substring(1)}`;
  }
  if (phone.length === 12 && phone.startsWith("0")) {
    return `0049${phone.substring(1)}`;
  }
  if (phone.length >= 12 && phone.startsWith("49")) {
    return `00${phone}`;
  }
  if (phone.length >= 13 && phone.startsWith("049")) {
    return `0${phone}`;
  }
  if (phone.length >= 14 && phone.startsWith("0049")) {
    return phone;
  }

  // JP (length: 10)
  if (phone.length === 12 && phone.startsWith("81")) {
    return `00${phone}`;
  }
  if (phone.length === 13 && phone.startsWith("081")) {
    return `0${phone}`;
  }
  if (phone.length === 14 && phone.startsWith("0081")) {
    return phone;
  }
};

export const loadScriptHead = (url) =>
  new Promise((resolve, reject) => {
    if (!document) {
      reject(new Error("Document was not defined"));
    }
    const head = document.getElementsByTagName("head")[0];

    const oldScript = document.querySelector(`script[src='${url}']`);
    if (oldScript) {
      // Remove script
      oldScript.remove();
    }

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = url;
    script.async = true;

    script.onreadystatechange = () => {
      resolve(script);
    };
    script.onload = script.onreadystatechange;

    script.onerror = () => {
      reject(new Error("Error loading script."));
    };

    script.onabort = (msg) => {
      console.log(msg);
      reject(new Error("Script loading aboirted."));
    };

    if (head.parentNode != null) {
      head.parentNode.insertBefore(script, head);
    }
  });

export const loadScript = (url) =>
  new Promise((resolve, reject) => {
    if (!document) {
      reject(new Error("Document was not defined"));
    }
    const tag = document.getElementsByTagName("script")[0];
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = url;
    script.async = true;
    script.onreadystatechange = () => {
      resolve(script);
    };
    script.onload = script.onreadystatechange;

    script.onerror = (msg) => {
      console.log(msg);
      reject(new Error("Error loading script."));
    };

    script.onabort = (msg) => {
      console.log(msg);
      reject(new Error("Script loading aboirted."));
    };

    if (tag.parentNode != null) {
      tag.parentNode.insertBefore(script, tag);
    }
  });

/**
 * Poor man's markdown template engine. It doesn't process all possible cases but it works for the most common use cases such as :
 *
 * _{DISCOUNT} OFF_ Code: {CODE}  ==> [<span className="underline">10% OFF</span>, "Code: MA10"]
 * Code: *{CODE}* ==> ["Code: ", <span className="strong">MA10</span>]
 * Code: *_{CODE}_* ==> ["Code: ", <span className="strong"><span className="underline">MA10</span></span>]
 * [Join Club AL](/account/register) to *earn 2x gold pts* on purchases. ==>
 *          [<Link to="/account/register">Join Club AL</Link>, "to", ...]
 * mobile-only \n linebreak ==> ["mobile-only", <br className="mobile-only-linebreak"/>, "linebreak"]
 *
 * */
export function markdownFormat(sentence, varMap = []) {
  // resolve vars
  const regex = /\{([A-Z_]+)\}/g; // the g at the end prevents infinite-loop below
  let m;
  let resolvedSentence = "";
  let lastEnd = 0;
  do {
    m = regex.exec(sentence);
    if (m) {
      if (varMap.length() > 0) {
        const prefix = sentence?.slice(lastEnd, m.index);
        resolvedSentence += prefix + (varMap[m[1]] ?? m[1]);
      }
      lastEnd = m.index + m[0].length;
    }
  } while (m);
  const tail = sentence?.slice(lastEnd);
  resolvedSentence += tail;

  // process styles (underline and bold)
  const stack = [];
  const formatted = [];
  let s = "";
  for (let i = 0; i < resolvedSentence.length; i++) {
    const c = resolvedSentence[i];
    switch (c) {
      case "*":
      case "_":
        if (stack.length > 0 && stack[stack.length - 1] === c) {
          stack.pop();
          if (s.length > 0) {
            formatted.push(s);
            s = "";
          }
          formatted[formatted.length - 1] = (
            <span
              style={c === "*" ? { fontWeight: "bold" } : { textDecoration: "underline" }}
              key={i}
            >
              {formatted[formatted.length - 1]}
            </span>
          );
        } else {
          stack.push(c);
        }
        break;
      default:
        if (stack.length === 0) {
          if (typeof formatted[formatted.length - 1] === "string")
            formatted[formatted.length - 1] += c;
          else formatted.push(c);
        } else {
          s += c;
        }
    }
  }
  if (s.length > 0) formatted.push(s);

  return formatted;
}

export function fRound(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function createSessionUserId() {
  const userId = generateUniqueID();
  if (isBrowser) {
    window.sessionStorage.setItem("sessionUserId", userId);
  }
  return userId;
}

export function getSessionUserId() {
  if (isBrowser) {
    const userId = window.sessionStorage.getItem("sessionUserId") || createSessionUserId();
    return userId;
  }
  return null;
}

export function simpleCipher(string) {
  const m = 10;
  const _in = 1;
  const _ax = _in + 1;
  const k = Math.floor(Math.random() * (m * _ax - m * _in + 1) + m * _in);
  const s = createHash(string, k - 1) + string;
  const ss = `${k}${String.fromCharCode(m * (m - _in) + m / 2 + _ax)}${s}`;
  return ss + createHash(ss, m);
}

export function simpleDecipher(string) {
  const m = 10;
  const h = createHash(string, m);
  const fullY = parseInt(string);
  if (!fullY || fullY < 10 || fullY > m * 2) return "";
  const y = fullY / h.length;
  return (h + string).substring(
    (h.length / m) * 2 + (m * y + h.length),
    h.length + string.length - m
  );
}

export function hashEmail(email) {
  if (email) {
    const hash = crypto.createHash("sha256").update(email.trim().toLowerCase());
    return hash.digest("hex");
  }
  return email;
}

/**
 * This function produces an object with the same structure as
 * what's created in the old code with setProduct.
 *
 * Locale is taken into account too.
 *
 * {
 *    node
 *    contentful
 * }
 *
 */
export function populateProduct(data, locale = "en-US") {
  // contentful field
  const contentfulEdges = data?.allContentfulProductPage?.edges;

  let localeContentful;
  if (contentfulEdges && contentfulEdges.length > 0) {
    localeContentful = contentfulEdges[0].node;
    for (const e of contentfulEdges) {
      if (e.node.node_locale === locale) {
        localeContentful = e.node;
        break;
      }
    }
  }
  // shopify field
  const shopify = data.shopifyProduct;
  return {
    node: shopify,
    contentful: localeContentful,
  };
}

export async function getCustomerAccessTokenCreateWithMultipass(multipassToken, store) {
  const tokenQuery = `
  mutation customerAccessTokenCreateWithMultipass {
    customerAccessTokenCreateWithMultipass(multipassToken: "${multipassToken}") {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
  `;
  const resultToken = await fetch(`https://${store.name}.myshopify.com/api/2022-10/graphql.json`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": store.storefrontAccessToken,
    },
    body: tokenQuery,
  });

  if (!resultToken.ok) {
    throw new Error(`${resultToken.status} ${resultToken.statusText}`);
  }

  const body = await resultToken.json();
  const { customerAccessToken, customerUserErrors } =
    body.data.customerAccessTokenCreateWithMultipass;

  if (customerUserErrors?.length) {
    console.error(customerUserErrors);
    throw new Error(`${customerUserErrors.code} ${customerUserErrors.message}`);
  }
  return customerAccessToken;
}
