import { forwardRef, useReducer, useRef, memo, useCallback } from "react";
import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import SlideOutMenu from "../SlideOutMenu";
import CategoryMenu from "./mobileMenu/CategoryMenu";
import BottomMenu from "./mobileMenu/BottomMenu";
import IconArrow from "../icons/svgs/arrow-circle-sm.svg";
import IconClose from "../icons/svgs/close-round.svg";
import { ALLogo } from "../ALComponents";

import "./mobileMenu/styles.scss";

function HeaderContent({ toggleSlideout }) {
  return (
    <div className="mobile_menu_header">
      <ALLogo className="al_logo" />
      <button
        className="mobile_menu_header_close"
        onClick={toggleSlideout}
        aria-labelledby="close-button-label"
      >
        <span id="close-button-label" hidden>
          Close menu
        </span>
        <IconClose width={30} fill="none" stroke="#2D2927" />
      </button>
    </div>
  );
}

const MobileMenu = forwardRef(({ isOpen, toggleSlideout }, ref) => {
  // We are using a reducer here due to having to manage a lot of complex state
  // Components are difficult to debug if they have many state objects
  const reducer = (state, action) => {
    switch (action.type) {
      // Close one of the category menus (i.e. 'Trending' or 'Earrings')
      case "deactivateCategoryMenu":
        // Set all category statuses to 'false' to keep 'aria-expanded' attribute accurate (a11y)
        const newCategoryMenuStatus = {};
        for (const key in state.categoryMenuStatus) {
          newCategoryMenuStatus[key] = false;
        }
        // Wait for the sliding animation to finish before focusing on the Menu close button (a11y)
        if (ref && ref.current !== null) {
          setTimeout(() => ref.current.focus(), 300);
        }
        return {
          ...state,
          subMenuAlign: "right",
          subContentIsOpen: false,
          mainContentIsOpen: true,
          categoryMenuStatus: newCategoryMenuStatus,
        };
      // Close one of the sub-menus (.i.e 'Trending --> Gifts')
      case "deactivateSubMenu":
        // Set all sub-menu statuses to 'false' to keep 'aria-expanded' attribute accurate (a11y)
        const newSubMenuStatus = {};
        for (const key in state.subMenuStatus) {
          newSubMenuStatus[key] = false;
        }
        // Wait for the sliding animation to finish before focusing on the Category menu back arrow (a11y)
        if (categoryMenuBackArrowRef && categoryMenuBackArrowRef.current !== null) {
          setTimeout(() => categoryMenuBackArrowRef.current.focus(), 300);
        }
        return {
          ...state,
          subSubContentIsOpen: false,
          subContentIsOpen: true,
          subMenuStatus: newSubMenuStatus,
        };
      case "deactivateAllMenus":
        return initialState;
      // Open a category menu (i.e. 'Trending')
      case "activateCategoryMenu":
        // Wait for the sliding animation to finish before focusing on the Category menu back arrow (a11y)
        if (categoryMenuBackArrowRef && categoryMenuBackArrowRef.current !== null) {
          setTimeout(() => categoryMenuBackArrowRef.current.focus(), 300);
        }
        return {
          ...state,
          categoryMenuContent: action.payload.content,
          subContentIsOpen: true,
          mainContentIsOpen: false,
          categoryMenuStatus: { ...state.categoryMenuStatus, [action.payload.menuName]: true },
          subMenuAlign: "left",
        };
      // Open a sub-menu (i.e. 'Trending --> Gifts')
      case "activateSubMenu":
        // Wait for the sliding animation to finish before focusing on the sub-menu back arrow (a11y)
        if (subMenuBackArrowRef && subMenuBackArrowRef.current !== null) {
          setTimeout(() => subMenuBackArrowRef.current.focus(), 300);
        }
        return {
          ...state,
          subMenuContent: action.payload.content,
          subSubContentIsOpen: true,
          subContentIsOpen: false,
          subMenuStatus: { ...state.subMenuStatus, [action.payload.menuName]: true },
        };
      // Set the initial menu statuses all to false, so they can be updated when clicking a menu option
      // This allows 'aria-expanded' attributes for menus to be tracked for a11y purposes
      case "initializeMenuStatuses":
        return {
          ...state,
          categoryMenuStatus: action.payload.categoryMenuStatus,
          subMenuStatus: action.payload.subMenuStatus,
        };
      case "toggleMainContent":
        return {
          ...state,
          mainContentIsOpen: !state.mainContentIsOpen,
        };
      case "toggleSubContentIsOpen":
        return {
          ...state,
          subContentIsOPen: !state.subContentIsOpen,
        };
      default:
        throw new Error();
    }
  };

  const initialState = {
    mainContentIsOpen: true,
    subContentIsOpen: false,
    categoryMenuContent: null,
    subSubContentIsOpen: false,
    subMenuContent: null,
    subMenuStatus: null,
    categoryMenuStatus: null,
    subMenuAlign: "right",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetAndCloseMenu = useCallback(() => {
    dispatch({ type: "deactivateAllMenus" });
    toggleSlideout();
  }, [toggleSlideout]);

  const categoryMenuBackArrowRef = useRef(null);
  let categoryMenuLabel = null;
  if ("categoryMenuStatus" in state && state.categoryMenuStatus != null) {
    categoryMenuLabel = Object.keys(state.categoryMenuStatus).map((e) => {
      if (state.categoryMenuStatus[e]) {
        return e;
      }
    });
  }
  const categoryHeaderContent = (
    <div className="mobile_submenu_header">
      <div className="mobile_submenu_header_container">
        <button
          type="button"
          className="mobile_submenu_header_back"
          onClick={() => dispatch({ type: "deactivateCategoryMenu" })}
          ref={categoryMenuBackArrowRef}
        >
          <IconArrow width="25" stroke="#2D2927" fill="none" />
        </button>
        <span className="h2 h2--bold">{categoryMenuLabel}</span>
        <button
          type="button"
          className="mobile_submenu_header_close"
          onClick={resetAndCloseMenu}
          aria-labelledby="close-submenu-button"
        >
          <IconClose width={30} fill="none" stroke="#2D2927" />
        </button>
      </div>
    </div>
  );

  const subMenuBackArrowRef = useRef(null);
  let subCategoryMenuLabel = null;
  if ("subMenuStatus" in state && state.subMenuStatus != null) {
    subCategoryMenuLabel = Object.keys(state.subMenuStatus).map((e) => {
      if (state.subMenuStatus[e]) {
        return e;
      }
    });
  }
  const subMenuHeaderContent = (
    <div className="mobile_submenu_header">
      <div className="mobile_submenu_header_container">
        <button
          className="mobile_submenu_header_back"
          onClick={() => dispatch({ type: "deactivateSubMenu" })}
          ref={categoryMenuBackArrowRef}
          aria-labelledby="menu-back-button"
        >
          <IconArrow />
          <span id="menu-back-button" hidden>
            Go back
          </span>
          <span className="al_p">{categoryMenuLabel}</span>
        </button>
        <button
          className="mobile_submenu_header_close"
          onClick={toggleSlideout}
          aria-labelledby="close-subsubmenu-button"
        >
          <span id="close-subsubmenu-button" hidden>
            Close sub-sub-menu
          </span>
          <IconClose />
        </button>
      </div>
      <span className="mobile_submenu_header_title">{subCategoryMenuLabel}</span>
    </div>
  );
  return (
    <SlideOutMenu align="left" isSlideoutOpen={isOpen} toggleSlideout={toggleSlideout}>
      <SlideOutMenu
        align="left"
        isSlideoutOpen={state.mainContentIsOpen}
        toggleSlideout={() => dispatch({ type: "toggleMainContentIsOpen" })}
        headerContent={<HeaderContent toggleSlideout={toggleSlideout} />}
        className="mobile_menu_slider"
      >
        <CategoryMenu
          initializeMenuStatuses={(categoryMenuStatus, subMenuStatus) =>
            dispatch({
              type: "initializeMenuStatuses",
              payload: { categoryMenuStatus, subMenuStatus },
            })
          }
          activateCategoryMenu={(content, menuName) =>
            dispatch({ type: "activateCategoryMenu", payload: { content, menuName } })
          }
          categoryMenuStatus={state.categoryMenuStatus}
          activateSubMenu={(content, menuName) =>
            dispatch({ type: "activateSubMenu", payload: { content, menuName } })
          }
          closeMenu={resetAndCloseMenu}
        />
        <BottomMenu closeMenu={resetAndCloseMenu} />
      </SlideOutMenu>
      <SlideOutMenu
        align={state.subMenuAlign}
        isSlideoutOpen={state.subContentIsOpen}
        toggleSlideout={() => dispatch({ type: "toggleSubContentIsOpen" })}
        headerContent={categoryHeaderContent}
      >
        {state.categoryMenuContent}
      </SlideOutMenu>
      <SlideOutMenu
        align="right"
        isSlideoutOpen={state.subSubContentIsOpen}
        toggleSlideout={() => dispatch({ type: "toggleSubSubContent" })}
        headerContent={subMenuHeaderContent}
      >
        {state.subMenuContent}
      </SlideOutMenu>
    </SlideOutMenu>
  );
});

MobileMenu.displayName = "MobileMenu";

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  toggleSlideout: PropTypes.func,
};

export default memo(
  withALErrorBoundary({
    name: "MobileMenu",
    priority: "P2",
  })(MobileMenu)
);
